import React from "react";
import showcase1 from "../../assets/images/intern/internshow1.svg";
import showcase2 from "../../assets/images/intern/internshow2.svg";
import showcase3 from "../../assets/images/intern/internshow3.svg";
import showcase4 from "../../assets/images/intern/internshow4.svg";
import showcase5 from "../../assets/images/intern/internshow5.svg";
import { Link } from "react-router-dom";
import showcase6 from "../../assets/images/intern/internshow6.svg";
import showcase7 from "../../assets/images/intern/internshow7.svg";
import intern1 from "../../assets/images/intern/intern1.png";
import intern2 from "../../assets/images/intern/intern2.png";
import intern3 from "../../assets/images/intern/intern3.png";
import intern4 from "../../assets/images/intern/intern4.png";
import intern5 from "../../assets/images/intern/intern5.png";
import intern6 from "../../assets/images/intern/intern6.png";
import solution from "../../assets/images/CRM-img/IT.png";
import line from "../../assets/images/CRM-img/line.svg";
import teacher1 from "../../assets/images/intern/profile1.png";
import teacher2 from "../../assets/images/intern/profile2.png";
import teacher3 from "../../assets/images/intern/profile3.png";
import teacher4 from "../../assets/images/intern/profile4.png";
import commatop from "../../assets/images/intern/comatop.svg";
import commabottom from "../../assets/images/intern/comabottom.svg";
import dp from "../../assets/images/intern/internProfile.svg";
import circle from "../../assets/images/intern/circle.png";
import rhombus from "../../assets/images/intern/rhombus.png";

import { FaUserAlt } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { GiTeacher } from "react-icons/gi";
import { IoDocument } from "react-icons/io5";
import { GrStar } from "react-icons/gr";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const Intern = () => {
  return (
    <div className="intern">
      <Helmet>
        <title>TechiesGateway - Training Program</title>
      </Helmet>
      <div className="intern-headingContainer">
        <div className="intern-top">
          Continue with <span>Techies Gateway ✨</span>
        </div>
        <h1 className="intern-heading">
          Launch Your Tech Career with Powerful <br /> Internships & Expert
          Guidance
        </h1>
        <p>
          Launch your career with powerful internships and expert guidance,
          gaining <br /> in-demand skills and landing your dream job in
          software.
        </p>
        {/* <div className="intern-btn0">Find Perfect Internship</div> */}
        <Link to="https://forms.gle/Lz1hZotgQvDBZEh56" target="_blank">
          <div className="intern-btn">Register Free Workshop</div>
        </Link>
      </div>
      <div className="intern-showcase">
        <div className="circle">
          <img src={circle} alt="" />
        </div>
        <img src={showcase1} alt="img" className="showcase1" />
        <img src={showcase2} alt="img" className="showcase2" />
        <img src={showcase3} alt="img" className="showcase3" />
        <img src={showcase4} alt="img" className="showcase4" />

        <img src={showcase5} alt="img" className="showcase5" />
        <img src={showcase6} alt="img" className="showcase6" />
        <img src={showcase7} alt="img" className="showcase7" />
      </div>
      <div className="intern-features">
        <div className="intern-features-box">
          <div className="intern-features-logo">
            <FaUserAlt />
          </div>
          <h4 className="intern-features-heading">Industrial training</h4>
          <p>We are providing how to actually work industry.</p>
        </div>
        <div className="intern-features-box">
          <div className="intern-features-logo">
            <IoDocument />
          </div>
          <h4 className="intern-features-heading">Live Project</h4>
          <p>
            we are working on Live projects and chance to interact with client.
          </p>
        </div>
        <div className="intern-features-box">
          <div className="intern-features-logo">
            <FaLinkedin />
          </div>
          <h4 className="intern-features-heading">Job portal guidance</h4>
          <p>
            we are guide to create a social handle and help to optimize as a
            professional.
          </p>
        </div>
        <div className="intern-features-box">
          <div className="intern-features-logo">
            <GiTeacher />
          </div>
          <h4 className="intern-features-heading">Interview training</h4>
          <p>
            We are also providing internship training and chance to join with
            us.
          </p>
        </div>
      </div>

      <div className="intern-training">
        <h2 class="home-service-heading">Internship Training</h2>
        <p class="home-service-custom">
          <strong>Choose your career with us</strong>
        </p>
        <div class="underline">
          <img src={line} alt="underline" />
        </div>
        <div className="intern-training-boxes">
          <div className="intern-training-box">
            <div className="intern-training-box-img">
              <img src={intern1} alt="" />
            </div>
            <div className="intern-training-box-heading">
              Full stack Development
            </div>
            <div className="intern-training-box-duration">60 days Training</div>
            <div className="intern-training-box-bottom">
              <div className="intern-training-box-teachers">
                <div className="teachers">
                  <img src={teacher1} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher2} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher3} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher4} alt="teacher" />
                </div>
              </div>
              <button className="intern-training-box-btn">Register Now</button>
            </div>
          </div>
          <div className="intern-training-box">
            <div className="intern-training-box-img">
              <img src={intern2} alt="" />
            </div>
            <div className="intern-training-box-heading">
              Frontend Development
            </div>
            <div className="intern-training-box-duration">45 days Training</div>
            <div className="intern-training-box-bottom">
              <div className="intern-training-box-teachers">
                <div className="teachers">
                  <img src={teacher1} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher2} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher3} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher4} alt="teacher" />
                </div>
              </div>
              <button className="intern-training-box-btn">Register Now</button>
            </div>
          </div>
          <div className="intern-training-box">
            <div className="intern-training-box-img">
              <img src={intern3} alt="" />
            </div>
            <div className="intern-training-box-heading">
              Backend Development
            </div>
            <div className="intern-training-box-duration">60 days Training</div>
            <div className="intern-training-box-bottom">
              <div className="intern-training-box-teachers">
                <div className="teachers">
                  <img src={teacher1} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher2} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher3} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher4} alt="teacher" />
                </div>
              </div>
              <button className="intern-training-box-btn">Register Now</button>
            </div>
          </div>
          <div className="intern-training-box">
            <div className="intern-training-box-img">
              <img src={intern4} alt="" />
            </div>
            <div className="intern-training-box-heading">App Development</div>
            <div className="intern-training-box-duration">60 days Training</div>
            <div className="intern-training-box-bottom">
              <div className="intern-training-box-teachers">
                <div className="teachers">
                  <img src={teacher1} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher2} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher3} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher4} alt="teacher" />
                </div>
              </div>
              <button className="intern-training-box-btn">Register Now</button>
            </div>
          </div>
          <div className="intern-training-box">
            <div className="intern-training-box-img">
              <img src={intern5} alt="" />
            </div>
            <div className="intern-training-box-heading">UX/UI Design</div>
            <div className="intern-training-box-duration">45 days Training</div>
            <div className="intern-training-box-bottom">
              <div className="intern-training-box-teachers">
                <div className="teachers">
                  <img src={teacher1} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher2} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher3} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher4} alt="teacher" />
                </div>
              </div>
              <button className="intern-training-box-btn">Register Now</button>
            </div>
          </div>
          <div className="intern-training-box">
            <div className="intern-training-box-img">
              <img src={intern6} alt="" />
            </div>
            <div className="intern-training-box-heading">
              Wordpress Development
            </div>
            <div className="intern-training-box-duration">45 days Training</div>
            <div className="intern-training-box-bottom">
              <div className="intern-training-box-teachers">
                <div className="teachers">
                  <img src={teacher1} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher2} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher3} alt="teacher" />
                </div>
                <div className="teachers">
                  <img src={teacher4} alt="teacher" />
                </div>
              </div>
              <button className="intern-training-box-btn">Register Now</button>
            </div>
          </div>
        </div>
      </div>
      <div className="rhombus">
        <img src={rhombus} alt="" />
      </div>
      <div className="web-solutions">
        <div className="web-solutions-top">
          <h2 className="web-top-heading blueText">Performance Parameter</h2>
          <p class="web-top-custom">
            <strong>IT Solutions You Trust</strong>
          </p>
          <div class="web-underline">
            <img src={line} alt="underline" />
          </div>
        </div>
        <div className="web-boxes ">
          <div className="web-solutions-box">
            <div className="web-solutions-box-img">
              <img src={solution} alt="solutions" />
            </div>
            <div className="web-solutions-box-heading">Best Mentorship</div>
            <div className="web-solutions-box-content">
              Level up your internship! Get matched with a personal best mentor
              for insider tips, real-time feedback.
            </div>
          </div>
          <div className="web-solutions-box">
            <div className="web-solutions-box-img">
              <img src={solution} alt="solutions" />
            </div>
            <div className="web-solutions-box-heading">
              Recognised for excellence
            </div>
            <div className="web-solutions-box-content">
              Spotlight on Stars! "Recognized for Excellence" honors those who
              go above & beyond at the company. Nominate today!
            </div>
          </div>
          <div className="web-solutions-box">
            <div className="web-solutions-box-img">
              <img src={solution} alt="solutions" />
            </div>
            <div className="web-solutions-box-heading">
              24*7 hr Help support
            </div>
            <div className="web-solutions-box-content">
              Our 24/7 Help Support is here to assist you anytime, any day. our
              expert team is always available.
            </div>
          </div>
          <div className="web-solutions-box">
            <div className="web-solutions-box-img">
              <img src={solution} alt="solutions" />
            </div>
            <div className="web-solutions-box-heading">
              Certificate & Goodies
            </div>
            <div className="web-solutions-box-content">
              We are provide a certificate show your resume and for best
              students, we give a goodies for excellent works.
            </div>
          </div>
        </div>
      </div>
      <div className="intern-review">
        <Swiper
          navigation={{
            navigation: true,
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 15000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="intern-review-swiper">
              <div className="intern-review-left">
                <h1 className="intern-review-heading">
                  <div className="commatop">
                    <img src={commatop} alt="" />
                  </div>
                  Let’s Explore Experiences and Learning <br /> towards our
                  offerings.
                </h1>
                <p className="intern-review-desc">
                  Discover what past interns are saying about our internship
                  program. Dive into the feedback on the quality and impact of
                  the learning experiences we offer. Gain insights into how our
                  interns perceive the professional growth and opportunities
                  provided during their time with us. Your opinions matter, and
                  we're here to listen.
                  <div className="commabottom">
                    <img src={commabottom} alt="" />
                  </div>
                </p>
                <Link
                  to="https://www.google.com/search?q=techiesgateway&rlz=1C1OPNX_enIN1099IN1099&oq=techiesgateway&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGDwyBggCEEUYPDIGCAMQRRg8MgYIBBBFGDwyBggFEEUYPNIBCDMzOTZqMGo5qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x39f49d4d72549325:0x82b37e74a2c8a7eb,1,,,,"
                  target="_blank"
                >
                  {" "}
                  <button className="intern-review-btn">Write a Review</button>
                </Link>
              </div>
              <div className="intern-review-right">
                <img src={dp} alt="" />
                <div className="intern-review-name">Dev Kant Kumar</div>
                <div className="intern-review-desc">
                  I had an enriching two-month internship at Techies Gateway,
                  mastering web development from HTML and CSS basics to the MERN
                  stack. I developed projects like a portfolio site, a typing
                  speed test, and a robust To-Do app. Additionally, I gained
                  UI/UX design skills using Canva and Figma. The experience
                  significantly boosted my confidence and industry readiness.
                </div>
                <div className="review-stars">
                  <GrStar className="blueStar" />
                  <GrStar className="blueStar" />
                  <GrStar className="blueStar" />
                  <GrStar className="blueStar" />
                  <GrStar className="greyStar" />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="intern-review-swiper">
              <div className="intern-review-left">
                <h1 className="intern-review-heading">
                  <div className="commatop">
                    <img src={commatop} alt="" />
                  </div>
                  Let’s Explore Experiences and Learning <br /> towards our
                  offerings.
                </h1>
                <p className="intern-review-desc">
                  Discover what past interns are saying about our internship
                  program. Dive into the feedback on the quality and impact of
                  the learning experiences we offer. Gain insights into how our
                  interns perceive the professional growth and opportunities
                  provided during their time with us. Your opinions matter, and
                  we're here to listen.
                  <div className="commabottom">
                    <img src={commabottom} alt="" />
                  </div>
                </p>
                <Link
                  to="https://www.google.com/search?q=techiesgateway&rlz=1C1OPNX_enIN1099IN1099&oq=techiesgateway&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGDwyBggCEEUYPDIGCAMQRRg8MgYIBBBFGDwyBggFEEUYPNIBCDMzOTZqMGo5qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x39f49d4d72549325:0x82b37e74a2c8a7eb,1,,,,"
                  target="_blank"
                >
                  {" "}
                  <button className="intern-review-btn">Write a Review</button>
                </Link>{" "}
              </div>
              <div className="intern-review-right">
                <img src={dp} alt="" />
                <div className="intern-review-name">Pinki Kumari</div>
                <div className="intern-review-desc">
                  My internship at Techies Gateway was an insightful journey. I
                  learned a alot and got hand-on experience in e-commerce
                  website. The team was very supportive. Grateful for the chance
                  to grow professionally and work with amazing people!.
                </div>
                <div className="review-stars">
                  <GrStar className="blueStar" />
                  <GrStar className="blueStar" />
                  <GrStar className="blueStar" />
                  <GrStar className="blueStar" />
                  <GrStar className="greyStar" />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="intern-review-swiper">
              <div className="intern-review-left">
                <h1 className="intern-review-heading">
                  <div className="commatop">
                    <img src={commatop} alt="" />
                  </div>
                  Let’s Explore Experiences and Learning <br /> towards our
                  offerings.
                </h1>
                <p className="intern-review-desc">
                  Discover what past interns are saying about our internship
                  program. Dive into the feedback on the quality and impact of
                  the learning experiences we offer. Gain insights into how our
                  interns perceive the professional growth and opportunities
                  provided during their time with us. Your opinions matter, and
                  we're here to listen.
                  <div className="commabottom">
                    <img src={commabottom} alt="" />
                  </div>
                </p>
                <Link
                  to="https://www.google.com/search?q=techiesgateway&rlz=1C1OPNX_enIN1099IN1099&oq=techiesgateway&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGDwyBggCEEUYPDIGCAMQRRg8MgYIBBBFGDwyBggFEEUYPNIBCDMzOTZqMGo5qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x39f49d4d72549325:0x82b37e74a2c8a7eb,1,,,,"
                  target="_blank"
                >
                  {" "}
                  <button className="intern-review-btn">Write a Review</button>
                </Link>{" "}
              </div>
              <div className="intern-review-right">
                <img src={dp} alt="" />
                <div className="intern-review-name">Om Prakash Kumar</div>
                <div className="intern-review-desc">
                  I am currently intern at Techiesgateway, where I am gaining
                  hands-on experience with industry-level projects. The training
                  is highly practical, and the mentors are extremely
                  knowledgeable and supportive. This internship is an excellent
                  opportunity to bridge the gap between academic learning and
                  real-world application, providing invaluable insights and
                  skills for my future career.
                </div>
                <div className="review-stars">
                  <GrStar className="blueStar" />
                  <GrStar className="blueStar" />
                  <GrStar className="blueStar" />
                  <GrStar className="blueStar" />
                  <GrStar className="greyStar" />
                </div>
              </div>
            </div>
          </SwiperSlide>

          <div className="swiper-button-next">
            <div className="icon">
              <IoIosArrowRoundForward />
            </div>
          </div>
          <div className="swiper-button-prev">
            <div className="icon">
              <IoIosArrowRoundBack />
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default Intern;
