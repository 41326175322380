import React from "react";
import hero from "../../../assets/images/hms-img/hmshero.png";
import box1 from "../../../assets/images/hms-img/hmsbox1.png";
import box2 from "../../../assets/images/hms-img/hmsbox2.png";
import box3 from "../../../assets/images/hms-img/hmsbox3.png";
import box4 from "../../../assets/images/hms-img/hmsbox4.png";
import box5 from "../../../assets/images/hms-img/hmsbox5.png";
import box6 from "../../../assets/images/hms-img/hmsbox6.png";
import box7 from "../../../assets/images/hms-img/hmsbox7.png";
import box8 from "../../../assets/images/hms-img/hmsbox8.png";
import box9 from "../../../assets/images/hms-img/hmsbox9.png";
import benefit1 from "../../../assets/images/CRM-img/benefit1.png";
import benefit2 from "../../../assets/images/CRM-img/benefit2.png";
import benefit3 from "../../../assets/images/CRM-img/benefit3.png";
import benefit4 from "../../../assets/images/CRM-img/benefit4.png";
import solution from "../../../assets/images/CRM-img/IT.png";
import circle1 from "../../../assets/images/CRM-img/circle1.png";
import circle2 from "../../../assets/images/CRM-img/circle2.png";
import rhombus from "../../../assets/images/CRM-img/rectangle2.png";
import line from "../../../assets/images/CRM-img/line.svg";
import gif from "../../../assets/images/hms-img/hms.gif";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

function index() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>HMS - TechiesGateway</title>
      </Helmet>
      <div className="hms-main-img">
        <img src={hero} alt="hms" />
        <img className="circle1" src={circle1} alt="circle" />
      </div>
      <div className="hms-container">
        <img className="circle2" src={circle2} alt="circle" />
        <img className="rectangle2" src={rhombus} alt="rhombus" />
        <div className="hms-intro ">
          <div className="hms-intro-content">
            <div className="hms-intro-content-text1">HMS</div>
            <div className="hms-intro-content-text2">
              We Build <span> Hospital Management System</span>
            </div>
            <div className="hms-intro-content-text3">
              We build Hospital Management Systems that redefine healthcare
              operations. From patient records to staff management, our
              solutions enhance efficiency and patient care.
            </div>
            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();

              }} ><button class="hms-contact-us-button">Contact Us</button></NavLink>

          </div>
          <div className="hms-intro-img">
            <img className="hms-img1" src={gif} alt="hms" />
          </div>
        </div>
        <div className="hms-service ">
          <div className="hms-service-top">
            <h2 className="hms-top-heading blueText">Service</h2>
            <p class="hms-top-custom ">
              <strong>
                <span> Elevate your digital presence </span> with our HMS
              </strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="hms-boxes">
            <div className="hms-service-box">
              <div className="hms-service-box-img">
                <img src={box1} alt="records" />
              </div>
              <div className="hms-service-box-heading">
                Patient Registration and Records
              </div>
              <div className="hms-service-box-content">
                Streamline patient registration and records for efficient and
                organized healthcare management and improved patient experience.
              </div>
            </div>
            <div className="hms-service-box ">
              <div className="hms-service-box-img">
                <img src={box2} alt="app. scheduling" />
              </div>
              <div className="hms-service-box-heading">
                Appointment Scheduling
              </div>
              <div className="hms-service-box-content ">
                Streamline scheduling for patients and staff, enhancing
                healthcare accessibility and overall operational effectiveness.
              </div>
            </div>
            <div className="hms-service-box">
              <div className="hms-service-box-img">
                <img src={box3} alt="billing" />
              </div>
              <div className="hms-service-box-heading">
                Billing and Invoicing
              </div>
              <div className="hms-service-box-content">
                Streamline invoicing processes, ensuring accurate and efficient
                financial transactions for enhanced healthcare management and
                patient satisfaction.
              </div>
            </div>
            <div className="hms-service-box">
              <div className="hms-service-box-img">
                <img src={box4} alt="pharmacy management" />
              </div>
              <div className="hms-service-box-heading">Pharmacy Management</div>
              <div className="hms-service-box-content">
                From inventory management to prescription fulfillment,
                streamline processes for efficient and accurate pharmaceutical
                services within healthcare facilities.
              </div>
            </div>
            <div className="hms-service-box">
              <div className="hms-service-box-img">
                <img src={box5} alt="laboratory system" />
              </div>
              <div className="hms-service-box-heading">
                Laboratory Information System
              </div>
              <div className="hms-service-box-content">
                Streamline information management for accurate test results,
                efficient workflows, and enhanced overall laboratory
                performance.
              </div>
            </div>
            <div className="hms-service-box">
              <div className="hms-service-box-img">
                <img src={box6} alt="electronic records" />
              </div>
              <div className="hms-service-box-heading">
                Electronic Medical Records (EMR)
              </div>
              <div className="hms-service-box-content">
                Digitize patient data, enhance accessibility, and empower
                healthcare providers for comprehensive and efficient patient
                care.
              </div>
            </div>
            <div className="hms-service-box">
              <div className="hms-service-box-img">
                <img src={box7} alt="staff management" />
              </div>
              <div className="hms-service-box-heading">Staff Management</div>
              <div className="hms-service-box-content">
                Our system simplifies HR tasks, from scheduling to performance
                tracking, ensuring streamlined operations and a motivated
                healthcare team.
              </div>
            </div>
            <div className="hms-service-box">
              <div className="hms-service-box-img">
                <img src={box8} alt="analytics" />
              </div>
              <div className="hms-service-box-heading">
                Reporting and Analytics
              </div>
              <div className="hms-service-box-content">
                Robust reporting and analytics empower informed decision-making,
                ensuring healthcare administrators have a comprehensive view for
                strategic improvements.
              </div>
            </div>
            <div className="hms-service-box">
              <div className="hms-service-box-img">
                <img src={box9} alt="support" />
              </div>
              <div className="hms-service-box-heading">
                Support and Maintenance
              </div>
              <div className="hms-service-box-content">
                Offer ongoing support and maintenance services to address any
                issues, updates, or enhancements needed for the HMS.
              </div>
            </div>
          </div>
        </div>
        <div className="hms-benifits">
          <div className="hms-benifits-top">
            <h2 className="hms-top-heading ">Benifits</h2>
            <p class="hms-top-custom">
              <strong>Benifits you will get</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="hms-boxes">
            <div className="hms-benifits-box">
              <div className="hms-benifits-box-top">
                <img src={benefit1} alt="benefits" />
                <div className="hms-benifits-box-top-heading">
                  <span>User-Friendly </span>
                  Interface
                </div>
              </div>
              <div className="hms-benifits-box-content">
                Intuitive interface for seamless HMS navigation, ensuring
                user-friendly interactions and efficient healthcare management.
              </div>
            </div>
            <div className="hms-benifits-box">
              <div className="hms-benifits-box-top">
                <img src={benefit2} alt="benefits" />
                <div className="hms-benifits-box-top-heading ">
                  Customization and Scalability
                </div>
              </div>
              <div className="hms-benifits-box-content">
                Tailored to your needs, we offers seamless customization and
                scalability, adapting to your evolving healthcare requirements
                effortlessly.
              </div>
            </div>
            <div className="hms-benifits-box">
              <div className="hms-benifits-box-top">
                <img src={benefit3} alt="benefits" />
                <div className="hms-benifits-box-top-heading">
                  Data Security and Compliance
                </div>
              </div>
              <div className="hms-benifits-box-content">
                Our HMS prioritizes robust data protection, safeguarding patient
                information in accordance with healthcare regulations
              </div>
            </div>
            <div className="hms-benifits-box">
              <div className="hms-benifits-box-top">
                <img src={benefit4} alt="benefits" />
                <div className="hms-benifits-box-top-heading">
                  Dedicated Support
                </div>
              </div>
              <div className="hms-benifits-box-content">
                Our dedicated team ensures seamless assistance, resolving issues
                promptly to enhance your healthcare operations.
              </div>
            </div>
          </div>
        </div>
        <div className="hms-solutions">
          <div className="hms-solutions-top">
            <h2 className="hms-top-heading blueText">Performance Parameter</h2>
            <p class="hms-top-custom">
              <strong>IT Solutions You Trust</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="hms-boxes ">
            <div className="hms-solutions-box">
              <div className="hms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="hms-solutions-box-heading">Enhance Security</div>
              <div className="hms-solutions-box-content">
                Enhanced website security <br /> protects sensitive data,
                <br />
                safeguards against cyber
                <br /> threats, and fosters user trust.
              </div>
            </div>
            <div className="hms-solutions-box">
              <div className="hms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="hms-solutions-box-heading">Reliability</div>
              <div className="hms-solutions-box-content">
                It is the ability of a website to function as intended,
                providing users with a seamless and dependable experience.
              </div>
            </div>
            <div className="hms-solutions-box">
              <div className="hms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="hms-solutions-box-heading">Faster Loading</div>
              <div className="hms-solutions-box-content">
                It enhances user experience by reducing page load times and
                improving website
                <br /> responsiveness.
              </div>
            </div>
            <div className="hms-solutions-box">
              <div className="hms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="hms-solutions-box-heading">Scalability</div>
              <div className="hms-solutions-box-content">
                It ensures a website's ability to handle increasing traffic and
                data demands without compromising performance.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
