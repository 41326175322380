
import React from 'react'
import benefit1 from "../../../assets/images/CRM-img/benefit1.png"
import benefit2 from "../../../assets/images/CRM-img/benefit2.png"
import benefit3 from "../../../assets/images/CRM-img/benefit3.png"
import benefit4 from "../../../assets/images/CRM-img/benefit4.png"
import solution from "../../../assets/images/CRM-img/IT.png"
import circle1 from "../../../assets/images/CRM-img/circle1.png"
import circle2 from "../../../assets/images/CRM-img/circle2.png"
import rhombus from "../../../assets/images/CRM-img/rectangle2.png"
import line from "../../../assets/images/CRM-img/line.svg"
import gif from "../../../assets/images/lms-img/lmsgif.gif"
import hero from "../../../assets/images/lms-img/LMS1.webp"
import box1 from "../../../assets/images/lms-img/lmsbox1.png"
import box2 from "../../../assets/images/lms-img/lmsbox2.png"
import box3 from "../../../assets/images/lms-img/lmsbox3.png"
import box4 from "../../../assets/images/lms-img/lmsbox4.png"
import box5 from "../../../assets/images/lms-img/lmsbox5.png"
import box6 from "../../../assets/images/lms-img/lmsbox6.png"
import box7 from "../../../assets/images/lms-img/lmsbox7.png"
import box8 from "../../../assets/images/lms-img/lmsbox8.png"
import box9 from "../../../assets/images/lms-img/lmsbox9.png"
import { NavLink } from 'react-router-dom'



import { Helmet } from "react-helmet";


function index() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>LMS - TechiesGateway</title>
      </Helmet>
      <div className="lms-main-img">
        <img src={hero} alt="LMS" />
        <img className="circle1" src={circle1} alt="circle" />
      </div>
      <div className="lms-container">
        <img className="circle2" src={circle2} alt="circle" />
        <img className="rectangle2" src={rhombus} alt="rhombus" />
        <div className="lms-intro ">
          <div className="lms-intro-content">
            <div className="lms-intro-content-text1">LMS</div>
            <div className="lms-intro-content-text2">
              We Build <span> Learning Management System</span>
            </div>
            <div className="lms-intro-content-text3">
              Revolutionize education with our Learning Management System (LMS).
              Offering a user-friendly interface, collaborative tools, and
              robust analytics, our LMS empowers personalized learning journeys.
            </div>
            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();

              }} ><button class="lms-contact-us-button">Contact Us</button></NavLink>

          </div>
          <div className="lms-intro-img">
            <img className="lms-img1" src={gif} alt="LMS" />
          </div>
        </div>
        <div className="lms-service ">
          <div className="lms-service-top">
            <h2 className="lms-top-heading blueText">Service</h2>
            <p class="lms-top-custom ">
              <strong>
                <span> Elevate your digital presence </span> with our LMS
              </strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="lms-boxes">
            <div className="lms-service-box">
              <div className="lms-service-box-img">
                <img src={box1} alt="student info management" />
              </div>
              <div className="lms-service-box-heading">
                Student Information
                <br /> Management
              </div>
              <div className="lms-service-box-content">
                Efficiently manage student data with our SMS, ensuring accuracy
                and accessibility for streamlined educational processes.
              </div>
            </div>
            <div className="lms-service-box ">
              <div className="lms-service-box-img">
                <img src={box2} alt="attendance tracking" />
              </div>
              <div className="lms-service-box-heading ">
                Attendance Tracking
              </div>
              <div className="lms-service-box-content ">
                Automate attendance tracking seamlessly with our SMS, promoting
                accuracy and efficiency in monitoring student attendance.
              </div>
            </div>
            <div className="lms-service-box">
              <div className="lms-service-box-img">
                <img src={box3} alt="grade management" />
              </div>
              <div className="lms-service-box-heading">
                Grade and Transcript
                <br /> Management
              </div>
              <div className="lms-service-box-content">
                Streamline grading and transcripts with our SMS, ensuring
                accurate and organized management of student academic records.
              </div>
            </div>
            <div className="lms-service-box">
              <div className="lms-service-box-img">
                <img src={box4} alt="timetable management" />
              </div>
              <div className="lms-service-box-heading">
                Timetable Management
              </div>
              <div className="lms-service-box-content">
                Simplify timetable management with our SMS, ensuring efficient
                scheduling and organization for a streamlined academic
                experience.
              </div>
            </div>
            <div className="lms-service-box">
              <div className="lms-service-box-img">
                <img src={box5} alt="student portal" />
              </div>
              <div className="lms-service-box-heading">Student Portal</div>
              <div className="lms-service-box-content">
                Empower students with our Student Portal—seamless access to
                grades, schedules, and vital academic information for enhanced
                learning experiences.
              </div>
            </div>
            <div className="lms-service-box">
              <div className="lms-service-box-img">
                <img src={box6} alt="communication collab" />
              </div>
              <div className="lms-service-box-heading">
                Communication and
                <br /> Collaboration
              </div>
              <div className="lms-service-box-content">
                Facilitate communication and collaboration for enhanced student
                and teacher engagement within our streamlined educational
                platform.
              </div>
            </div>
            <div className="lms-service-box">
              <div className="lms-service-box-img">
                <img src={box7} alt="fee management" />
              </div>
              <div className="lms-service-box-heading">Fee Management</div>
              <div className="lms-service-box-content">
                Simplify fee management with our system—efficient invoicing,
                payment tracking, and financial reporting for streamlined
                financial processes.
              </div>
            </div>
            <div className="lms-service-box">
              <div className="lms-service-box-img">
                <img src={box8} alt="library management" />
              </div>
              <div className="lms-service-box-heading">Library Management</div>
              <div className="lms-service-box-content">
                Efficient library management: streamline book tracking,
                check-outs, and returns for an organized and accessible
                educational environment.
              </div>
            </div>
            <div className="lms-service-box">
              <div className="lms-service-box-img">
                <img src={box9} alt="exam and assessment" />
              </div>
              <div className="lms-service-box-heading">
                Examination and Assessment
              </div>
              <div className="lms-service-box-content">
                Optimize examinations with our system—seamless scheduling,
                grading, and result management for efficient assessment
                processes.
              </div>
            </div>
          </div>
        </div>
        <div className="lms-benifits">
          <div className="lms-benifits-top">
            <h2 className="lms-top-heading ">Benifits</h2>
            <p class="lms-top-custom">
              <strong>Benifits you will get</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="lms-boxes">
            <div className="lms-benifits-box">
              <div className="lms-benifits-box-top">
                <img src={benefit1} alt="benefits" />
                <div className="lms-benifits-box-top-heading">
                  <span>User-Friendly </span>
                  Interface
                </div>
              </div>
              <div className="lms-benifits-box-content">
                Intuitive LMS interface for seamless navigation, ensuring
                user-friendly interactions for streamlined student information
                management.
              </div>
            </div>
            <div className="lms-benifits-box">
              <div className="lms-benifits-box-top">
                <img src={benefit2} alt="benefits" />
                <div className="lms-benifits-box-top-heading ">
                  Customization and Scalability
                </div>
              </div>
              <div className="lms-benifits-box-content">
                Tailored to fit, our LMS offers seamless customization and
                scalability, adapting to evolving educational needs
                effortlessly.
              </div>
            </div>
            <div className="lms-benifits-box">
              <div className="lms-benifits-box-top">
                <img src={benefit3} alt="benefits" />
                <div className="lms-benifits-box-top-heading">
                  Data Security and Compliance
                </div>
              </div>
              <div className="lms-benifits-box-content">
                Ensuring data security and compliance, our SMS prioritizes
                robust protection, aligning with educational privacy regulations
                seamlessly.
              </div>
            </div>
            <div className="lms-benifits-box">
              <div className="lms-benifits-box-top">
                <img src={benefit4} alt="benefits" />
                <div className="lms-benifits-box-top-heading">
                  Dedicated Support
                </div>
              </div>
              <div className="lms-benifits-box-content">
                Experience unwavering support with our dedicated team, ensuring
                prompt assistance and seamless resolution for optimal system
                performance.
              </div>
            </div>
          </div>
        </div>
        <div className="lms-solutions">
          <div className="lms-solutions-top">
            <h2 className="lms-top-heading blueText">Performance Parameter</h2>
            <p class="lms-top-custom">
              <strong>IT Solutions You Trust</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="lms-boxes ">
            <div className="lms-solutions-box">
              <div className="lms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="lms-solutions-box-heading">Enhance Security</div>
              <div className="lms-solutions-box-content">
                Enhanced website security <br /> protects sensitive data,
                <br />
                safeguards against cyber
                <br /> threats, and fosters user trust.
              </div>
            </div>
            <div className="lms-solutions-box">
              <div className="lms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="lms-solutions-box-heading">Reliability</div>
              <div className="lms-solutions-box-content">
                It is the ability of a website to function as intended,
                providing users with a seamless and dependable experience.
              </div>
            </div>
            <div className="lms-solutions-box">
              <div className="lms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="lms-solutions-box-heading">Faster Loading</div>
              <div className="lms-solutions-box-content">
                It enhances user experience by reducing page load times and
                improving website
                <br /> responsiveness.
              </div>
            </div>
            <div className="lms-solutions-box">
              <div className="lms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="lms-solutions-box-heading">Scalability</div>
              <div className="lms-solutions-box-content">
                It ensures a website's ability to handle increasing traffic and
                data demands without compromising performance.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
