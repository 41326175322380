import React from "react";
import circle1 from "../../../assets/images/web-dev/web-circle.png";
import circle2 from "../../../assets/images/web-dev/webcircle2.png";
import rhombus from "../../../assets/images/web-dev/web-rectangle1.png";
import gif from "../../../assets/images/web-dev/web.gif";
import project1 from "../../../assets/images/web-dev/project1.svg";
import project2 from "../../../assets/images/web-dev/project2.svg";
import project3 from "../../../assets/images/web-dev/project3.svg";
import project4 from "../../../assets/images/web-dev/project4.svg";
import project5 from "../../../assets/images/web-dev/project5.svg";
import project6 from "../../../assets/images/web-dev/project6.svg";
import category1 from "../../../assets/images/web-dev/ca1.png";
import category2 from "../../../assets/images/web-dev/ca2.png";
import category3 from "../../../assets/images/web-dev/ca3-removebg-preview.png";
import category4 from "../../../assets/images/web-dev/ca4.png";
import category5 from "../../../assets/images/web-dev/ca5.png";
import category6 from "../../../assets/images/web-dev/ca6.png";
import category7 from "../../../assets/images/web-dev/ca7.png";
import category8 from "../../../assets/images/web-dev/ca8.png";
import category9 from "../../../assets/images/web-dev/ca9-removebg-preview.png";
import category10 from "../../../assets/images/web-dev/ca10-removebg-preview.png";
import category11 from "../../../assets/images/web-dev/ca11.png";
import category12 from "../../../assets/images/web-dev/ca12.png";
import benefit1 from "../../../assets/images/web-dev/be1.png";
import benefit2 from "../../../assets/images/web-dev/be2.png";
import benefit3 from "../../../assets/images/web-dev/be3.png";
import benefit4 from "../../../assets/images/web-dev/be4.png";
import benefit5 from "../../../assets/images/web-dev/be5-removebg-preview.png";
import benefit6 from "../../../assets/images/web-dev/be6.png";
import benefit7 from "../../../assets/images/web-dev/be7.png";
import benefit8 from "../../../assets/images/web-dev/be8.png";
import benefit9 from "../../../assets/images/web-dev/be9.png";
import benefit10 from "../../../assets/images/web-dev/be10.png";
import benefit11 from "../../../assets/images/web-dev/be11.png";
import icon5 from "../../../assets/images/web-dev/icon5.svg";
import icon1 from "../../../assets/images/web-dev/icon1.svg";
import icon2 from "../../../assets/images/web-dev/icon2.png";
import icon3 from "../../../assets/images/web-dev/icon3.svg";
import icon4 from "../../../assets/images/web-dev/icon4.svg";
import heroimg from "../../../assets/images/web-dev/introimg.webp";
import solution from "../../../assets/images/CRM-img/IT.png";
import line from "../../../assets/images/CRM-img/line.svg";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

function index() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>Web Development - TechiesGateway</title>
      </Helmet>
      <div className="web">
        <img className="web-circle1" src={circle2} alt="circle" />
        <img className="web-circle2" src={rhombus} alt="rhombus" />
        <img className="web-circle4" src={rhombus} alt="rhombus" />
        <img className="web-circle3" src={circle1} alt="circle" />
        <div className="web-intro">
          <div className="web-intro-content">
            <div className="web-intro-content-text1 web-intro-blueText">
              Web Development
            </div>
            <div className="web-intro-content-text2">
              We Build creative <span>Modern Website</span>
            </div>
            <div id="pricing-btn">Starts from ₹1000/-</div>
            <div className="web-intro-content-text3">
              Our team of creative web developers is passionate about
              transforming ideas into captivating and user-friendly websites
              that seamlessly align with our client’s brand identities and
              business objectives.
              <br />
            </div>
            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();
              }}
            >
              <button class="web-contact-us-button">Contact Us</button>
            </NavLink>
          </div>
          <div className="web-intro-img">
            <img className="web-img1" src={heroimg} alt="web development" />
          </div>
        </div>
        <div className="web-container">
          <div className="web-design">
            <div className="web-design-imgs">
              <img className="web-design-img" src={gif} alt="gif" />
            </div>
            <div className="web-design-content">
              <div className="web-top-design-heading ">
                Website Development/ Design
              </div>
              <div className="web-design-content-custom">
                <strong> Website Development/ Design</strong>
              </div>
              <div class="web-design-underline">
                <img src={line} alt="underline" />
              </div>
              <div className="web-design-content-description">
                We possess a deep understanding of current web design trends,
                user experience principles, and the latest web development
                technologies, enabling us to craft websites that not only engage
                users but also drive conversions.
              </div>
              <div className="web-design-icons">
                <div className="web-design-icon">
                  <img src={icon1} alt="tech1" />
                </div>
                <div className="web-design-icon">
                  <img src={icon2} alt="tech2" />
                </div>
                <div className="web-design-icon">
                  <img src={icon3} alt="tech3" />
                </div>
                <div className="web-design-icon">
                  <img src={icon4} alt="tech4" />
                </div>
                <div className="web-design-icon">
                  <img src={icon5} alt="icon5" />
                </div>
              </div>
              <NavLink
                to="/contact"
                onClick={() => {
                  gotoTop();
                }}
              >
                <button class="web-contact-us-button">Contact Us</button>
              </NavLink>
            </div>
          </div>
          <div className="web-category">
            <div className="web-category-top">
              <p className="web-top-heading">Category</p>
              <p class="web-top-custom ">
                <strong>Categories of website development</strong>
              </p>
              <div class="web-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="web-category-boxes web-boxes">
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category1} alt="categories" />
                </div>
                <div className="web-category-box-text">Corporate website</div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category2} alt="categories" />
                </div>
                <div className="web-category-box-text">Personal website</div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category3} alt="categories" />
                </div>
                <div className="web-category-box-text">Government website</div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category4} alt="categories" />
                </div>
                <div className="web-category-box-text">Ecommerce website</div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category5} alt="categories" />
                </div>
                <div className="web-category-box-text">Educational website</div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category6} alt="categories" />
                </div>
                <div className="web-category-box-text">NGO website</div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category7} alt="categories" />
                </div>
                <div className="web-category-box-text">
                  Health and wellness
                  <br /> website
                </div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category8} alt="categories" />
                </div>
                <div className="web-category-box-text">Restaurant website</div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category9} alt="categories" />
                </div>
                <div className="web-category-box-text">Blog/ News website</div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category10} alt="categories" />
                </div>
                <div className="web-category-box-text">
                  Event Ticket website
                </div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category11} alt="categories" />
                </div>
                <div className="web-category-box-text">Real State website</div>
              </div>
              <div className="web-category-box">
                <div className="web-category-box-img">
                  <img src={category12} alt="categories" />
                </div>
                <div className="web-category-box-text">
                  Travel and Tourism <br />
                  website
                </div>
              </div>
            </div>
          </div>
          <div className="web-benifits">
            <div className="web-benifits-top">
              <h2 className="web-top-heading ">Benifits</h2>
              <p class="web-top-custom">
                <strong>Benifits you will get</strong>
              </p>
              <div class="web-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="web-boxes">
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit1} alt="benefits" />
                  <div className="web-benifits-box-top-heading">
                    SEO Friendly
                  </div>
                </div>
                <div className="web-benifits-box-content">
                  It is the art and science of optimizing websites to rank
                  higher in search engine results pages (SERPs).
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit2} alt="benefits" />
                  <div className="web-benifits-box-top-heading">
                    1 Year Maintenance
                  </div>
                </div>
                <div className="web-benifits-box-content">
                  Comprehensive coverage for your systems and software over the
                  course of a year.
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit3} alt="benefits" />
                  <div className="web-benifits-box-top-heading">
                    Mobile Responsive
                  </div>
                </div>
                <div className="web-benifits-box-content">
                  Mobile responsive websites adapt their layout and content to
                  provide an optimal viewing experience on various screen sizes.
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit4} alt="benefits" />
                  <div className="web-benifits-box-top-heading">
                    Web Hosting
                  </div>
                </div>
                <div className="web-benifits-box-content">
                  Web hosting provides the storage and resources necessary for a
                  website to be accessible on the internet.
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit5} alt="benefits" />
                  <div className="web-benifits-box-top-heading">Security</div>
                </div>
                <div className="web-benifits-box-content">
                  Website security protects websites from unauthorized access,
                  data breaches, and other malicious cyberattacks.
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit6} alt="benefits" />
                  <div className="web-benifits-box-top-heading">Domain</div>
                </div>
                <div className="web-benifits-box-content">
                  A domain is a unique identifier for a website on the internet.
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit7} alt="benefits" />
                  <div className="web-benifits-box-top-heading">
                    Business Email
                  </div>
                </div>
                <div className="web-benifits-box-content">
                  Business email is a professional communication tool that helps
                  businesses connect with customers, partners and colleagues.
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit8} alt="benefits" />
                  <div className="web-benifits-box-top-heading">
                    WhatsApp Integrate
                  </div>
                </div>
                <div className="web-benifits-box-content">
                  WhatsApp integration enables seamless communication between
                  website visitors and business representatives through
                  WhatsApp.
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit9} alt="benefits" />
                  <div className="web-benifits-box-top-heading">Google Map</div>
                </div>
                <div className="web-benifits-box-content">
                  Google Maps is a web mapping service that provides detailed
                  street maps and real-time traffic conditions.
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit10} alt="benefits" />
                  <div className="web-benifits-box-top-heading">
                    Social Media Setup
                  </div>
                </div>
                <div className="web-benifits-box-content">
                  Social media setup involves creating and optimizing social
                  media profiles for a business or individual.
                </div>
              </div>
              <div className="web-benifits-box">
                <div className="web-benifits-box-top">
                  <img src={benefit11} alt="benefits" />
                  <div className="web-benifits-box-top-heading">Live Chat</div>
                </div>
                <div className="web-benifits-box-content">
                  Live chat provides real-time online customer support through
                  text-based chat windows.
                </div>
              </div>
            </div>
          </div>
          <div className="web-solutions">
            <div className="web-solutions-top">
              <h2 className="web-top-heading blueText">
                Performance Parameter
              </h2>
              <p class="web-top-custom">
                <strong>IT Solutions You Trust</strong>
              </p>
              <div class="web-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="web-boxes ">
              <div className="web-solutions-box">
                <div className="web-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="web-solutions-box-heading">
                  Enhance Security
                </div>
                <div className="web-solutions-box-content">
                  Enhanced website security <br /> protects sensitive data,
                  <br />
                  safeguards against cyber
                  <br /> threats, and fosters user trust.
                </div>
              </div>
              <div className="web-solutions-box">
                <div className="web-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="web-solutions-box-heading">Reliability</div>
                <div className="web-solutions-box-content">
                  It is the ability of a website to function as intended,
                  providing users with a seamless and dependable experience.
                </div>
              </div>
              <div className="web-solutions-box">
                <div className="web-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="web-solutions-box-heading">Faster Loading</div>
                <div className="web-solutions-box-content">
                  It enhances user experience
                  <br /> by reducing page load
                  <br /> times and improving website responsiveness.
                </div>
              </div>
              <div className="web-solutions-box">
                <div className="web-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="web-solutions-box-heading">Scalability</div>
                <div className="web-solutions-box-content">
                  It ensures a website's ability to handle increasing traffic
                  and data demands without compromising performance.
                </div>
              </div>
            </div>
          </div>
          <div className="web-samples">
            <div className="web-samples-top">
              <h2 className="web-top-heading ">Work Sample</h2>
              <p class="web-top-custom">
                <strong>Why you choose us</strong>
              </p>
              <div class="web-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="web-boxes">
              <div className="web-sample-box">
                <a
                  href="https://vbumca.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="web-sample-box-img">
                    <img src={project1} alt="project1" />
                  </div>
                </a>
                <div className="web-sample-box-contents">
                  <div className="web-sample-box-content1">
                    Educational website
                  </div>
                  <div className="web-sample-box-content2">
                    MCA Dept, Hazaribagh Jharkhand
                  </div>
                </div>
              </div>
              <div className="web-sample-box">
                <a
                  href="http://homecakeandbake.in
"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="web-sample-box-img">
                    <img src={project2} alt="project2" />
                  </div>
                </a>
                <div className="web-sample-box-contents">
                  <div className="web-sample-box-content1">
                    E-commerce single page Website
                  </div>
                  <div className="web-sample-box-content2">
                    Home Cake & Bake
                  </div>
                </div>
              </div>
              <div className="web-sample-box">
                <a
                  href="http://rajivrnjan.github.io/portfolio/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="web-sample-box-img">
                    <img src={project3} alt="project3" />
                  </div>
                </a>
                <div className="web-sample-box-contents">
                  <div className="web-sample-box-content1">
                    Portfolio Website
                  </div>
                  <div className="web-sample-box-content2">Rajiv Rajan</div>
                </div>
              </div>
              <div className="web-sample-box">
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <div className="web-sample-box-img">
                    <img src={project4} alt="project4" />
                  </div>
                </a>
                <div className="web-sample-box-contents">
                  <div className="web-sample-box-content1">Landing Page</div>
                  <div className="web-sample-box-content2">Astrotech</div>
                </div>
              </div>
              <div className="web-sample-box">
                <a
                  href="http://pranforyou.com
"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="web-sample-box-img">
                    <img src={project5} alt="project5" />
                  </div>
                </a>
                <div className="web-sample-box-contents">
                  <div className="web-sample-box-content1">
                    E-learning platform
                  </div>
                  <div className="web-sample-box-content2">PRAN</div>
                </div>
              </div>
              <div className="web-sample-box">
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <div className="web-sample-box-img">
                    <img src={project6} alt="project6" />
                  </div>
                </a>
                <div className="web-sample-box-contents">
                  <div className="web-sample-box-content1">Admin Panel</div>
                  <div className="web-sample-box-content2">PRAN</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
