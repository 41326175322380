import React from "react";
import benefit1 from "../../../assets/images/CRM-img/benefit1.png";
import benefit2 from "../../../assets/images/CRM-img/benefit2.png";
import benefit3 from "../../../assets/images/CRM-img/benefit3.png";
import benefit4 from "../../../assets/images/CRM-img/benefit4.png";
import solution from "../../../assets/images/CRM-img/IT.png";
import circle1 from "../../../assets/images/CRM-img/circle1.png";
import circle2 from "../../../assets/images/CRM-img/circle2.png";
import rhombus from "../../../assets/images/CRM-img/rectangle2.png";
import line from "../../../assets/images/CRM-img/line.svg";
import gif from "../../../assets/images/hrm-img/hrms.gif";
import hero from "../../../assets/images/hrm-img/hrmshero.png";
import box1 from "../../../assets/images/hrm-img/hrmsbox1.png";
import box2 from "../../../assets/images/hrm-img/hrmsbox2.png";
import box3 from "../../../assets/images/hrm-img/hrmsbox3.png";
import box4 from "../../../assets/images/hrm-img/hrmsbox4.png";
import box5 from "../../../assets/images/hrm-img/hrmsbox5.png";
import box6 from "../../../assets/images/hrm-img/hrmsbox6.png";
import box7 from "../../../assets/images/hrm-img/hrmsbox7.png";
import box8 from "../../../assets/images/hrm-img/hrmsbox8.png";
import box9 from "../../../assets/images/hrm-img/hrmsbox9.png";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const index = () => {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>HRM - TechiesGateway</title>
      </Helmet>
      <div className="hrm-main-img">
        <img src={hero} alt="hrm" />
        <img className="circle1" src={circle1} alt="circle" />
      </div>
      <div className="hrm-container">
        <img className="circle2" src={circle2} alt="circle" />
        <img className="rectangle2" src={rhombus} alt="rhombus" />
        <div className="hrm-intro ">
          <div className="hrm-intro-content">
            <div className="hrm-intro-content-text1">HRMS</div>
            <div className="hrm-intro-content-text2">
              We Build <span> Human Resource Management System</span>
            </div>
            <div className="hrm-intro-content-text3">
              We build Human Resource Management Systems (HRMS) that redefine
              workplace efficiency. Streamlining recruitment, payroll precision,
              and employee engagement, our HRMS solutions ensure a modern,
              integrated, and user-friendly experience tailored to your business
              needs. Elevate your HR processes with our expertise.
            </div>
            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();
              }}
            >
              <button class="hrm-contact-us-button">Contact Us</button>
            </NavLink>
          </div>
          <div className="hrm-intro-img">
            <img className="hrm-img1" src={gif} alt="hrm" />
          </div>
        </div>
        <div className="hrm-service ">
          <div className="hrm-service-top">
            <h2 className="hrm-top-heading blueText">Service</h2>
            <p class="hrm-top-custom ">
              <strong>
                <span> Elevate your digital presence </span> with our HRMS
              </strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="hrm-boxes">
            <div className="hrm-service-box">
              <div className="hrm-service-box-img">
                <img src={box1} alt="employee management" />
              </div>
              <div className="hrm-service-box-heading">
                Employee Information
                <br /> Management
              </div>
              <div className="hrm-service-box-content">
                Effortlessly manage employee information with our HRMS, ensuring
                accuracy and accessibility for streamlined HR processes.
              </div>
            </div>
            <div className="hrm-service-box">
              <div className="hrm-service-box-img">
                <img src={box2} alt="attendance management" />
              </div>
              <div className="hrm-service-box-heading">
                Attendance and Leave
                <br /> Management
              </div>
              <div className="hrm-service-box-content">
                Optimize attendance and leave management seamlessly with our
                HRMS, ensuring efficient workforce tracking and time-off
                processing.
              </div>
            </div>
            <div className="hrm-service-box">
              <div className="hrm-service-box-img">
                <img src={box3} alt="performance management" />
              </div>
              <div className="hrm-service-box-heading">
                Performance Management
              </div>
              <div className="hrm-service-box-content">
                Elevate performance with precision. Our HRMS empowers strategic
                performance management, fostering growth and enhancing
                organizational success.
              </div>
            </div>
            <div className="hrm-service-box">
              <div className="hrm-service-box-img">
                <img src={box4} alt="recruitment" />
              </div>
              <div className="hrm-service-box-heading">
                Recruitment and Onboarding
              </div>
              <div className="hrm-service-box-content">
                Revolutionize recruitment and onboarding with our HRMS.
                Streamline processes for efficient hiring and seamless employee
                integration.
              </div>
            </div>
            <div className="hrm-service-box">
              <div className="hrm-service-box-img">
                <img src={box5} alt="training" />
              </div>
              <div className="hrm-service-box-heading">
                Training and Development
              </div>
              <div className="hrm-service-box-content">
                Foster growth with our HRMS Training and Development module.
                Empower employees through continuous learning and skill
                enhancement.
              </div>
            </div>
            <div className="hrm-service-box">
              <div className="hrm-service-box-img">
                <img src={box6} alt="payroll and benefits" />
              </div>
              <div className="hrm-service-box-heading">
                Payroll and Benefits
                <br /> Administration
              </div>
              <div className="hrm-service-box-content">
                Seamless payroll and benefits administration with our HRMS.
                Ensure accuracy, compliance, and employee satisfaction in one
                integrated solution.
              </div>
            </div>
            <div className="hrm-service-box">
              <div className="hrm-service-box-img">
                <img src={box7} alt="employee self-service" />
              </div>
              <div className="hrm-service-box-heading">
                Employee Self-Service
              </div>
              <div className="hrm-service-box-content">
                Empower your workforce with our HRMS Employee Self-Service
                feature. Streamline access to pay stubs, leave requests, and
                personal information effortlessly.
              </div>
            </div>
            <div className="hrm-service-box">
              <div className="hrm-service-box-img">
                <img src={box8} alt="compliance and reporting" />
              </div>
              <div className="hrm-service-box-heading">
                Compliance and Reporting
              </div>
              <div className="hrm-service-box-content">
                Ensure regulatory compliance and gain insights with our HRMS.
                Simplify reporting for informed decision-making and a secure,
                compliant workplace.
              </div>
            </div>
            <div className="hrm-service-box">
              <div className="hrm-service-box-img">
                <img src={box9} alt="security measures" />
              </div>
              <div className="hrm-service-box-heading">Security Measures</div>
              <div className="hrm-service-box-content">
                Robust security safeguards ensure HRMS data integrity and
                protect sensitive employee information
              </div>
            </div>
          </div>
        </div>
        <div className="hrm-benifits">
          <div className="hrm-benifits-top">
            <h2 className="hrm-top-heading ">Benifits</h2>
            <p class="hrm-top-custom">
              <strong>Benifits you will get</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="hrm-boxes">
            <div className="hrm-benifits-box">
              <div className="hrm-benifits-box-top">
                <img src={benefit1} alt="benefits" />
                <div className="hrm-benifits-box-top-heading">
                  <span>User-Friendly </span>
                  Interface
                </div>
              </div>
              <div className="hrm-benifits-box-content">
                Intuitive interface for seamless HRMS navigation, ensuring
                user-friendly interactions and enhanced employee and HR
                experiences.
              </div>
            </div>
            <div className="hrm-benifits-box ">
              <div className="hrm-benifits-box-top">
                <img src={benefit2} alt="benefits" />
                <div className="hrm-benifits-box-top-heading ">
                  Customization and Scalability
                </div>
              </div>
              <div className="hrm-benifits-box-content">
                Our system offers seamless customization and scalability to
                adapt to your evolving organizational needs.
              </div>
            </div>
            <div className="hrm-benifits-box">
              <div className="hrm-benifits-box-top">
                <img src={benefit3} alt="benefits" />
                <div className="hrm-benifits-box-top-heading">
                  Data Security and Compliance
                </div>
              </div>
              <div className="hrm-benifits-box-content">
                Robust measures safeguard sensitive information, meeting
                regulatory standards for a secure work environment.
              </div>
            </div>
            <div className="hrm-benifits-box">
              <div className="hrm-benifits-box-top">
                <img src={benefit4} alt="benefits" />
                <div className="hrm-benifits-box-top-heading">
                  Dedicated Support
                </div>
              </div>
              <div className="hrm-benifits-box-content">
                Reliable support is our commitment. Experience dedicated
                assistance with our HRMS, ensuring a seamless and supported user
                experience.
              </div>
            </div>
          </div>
        </div>
        <div className="hrm-solutions">
          <div className="hrm-solutions-top">
            <h2 className="hrm-top-heading blueText">Performance Parameter</h2>
            <p class="hrm-top-custom">
              <strong>IT Solutions You Trust</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="hrm-boxes ">
            <div className="hrm-solutions-box">
              <div className="hrm-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="hrm-solutions-box-heading">Enhance Security</div>
              <div className="hrm-solutions-box-content">
                Enhanced website security <br /> protects sensitive data,
                <br />
                safeguards against cyber
                <br /> threats, and fosters user trust.
              </div>
            </div>
            <div className="hrm-solutions-box">
              <div className="hrm-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="hrm-solutions-box-heading">Reliability</div>
              <div className="hrm-solutions-box-content">
                It is the ability of a website to function as intended,
                providing users with a seamless and dependable experience.
              </div>
            </div>
            <div className="hrm-solutions-box">
              <div className="hrm-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="hrm-solutions-box-heading">Faster Loading</div>
              <div className="hrm-solutions-box-content">
                It enhances user experience by reducing page load times and
                improving website
                <br /> responsiveness.
              </div>
            </div>
            <div className="hrm-solutions-box">
              <div className="hrm-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="hrm-solutions-box-heading">Scalability</div>
              <div className="hrm-solutions-box-content">
                It ensures a website's ability to handle increasing traffic and
                data demands without compromising performance.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
