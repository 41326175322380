import React from "react";
import circle1 from "../../../assets/images/web-dev/web-circle.png";
import circle2 from "../../../assets/images/web-dev/webcircle2.png";
import rhombus from "../../../assets/images/web-dev/web-rectangle1.png";
import icon1 from "../../../assets/images/seo/icon1.png";
import icon2 from "../../../assets/images/seo/icon2.png";
import icon3 from "../../../assets/images/seo/icon3.png";
import icon4 from "../../../assets/images/seo/icon4.png";
import icon5 from "../../../assets/images/seo/icon5.png";
import icon6 from "../../../assets/images/seo/icon6.png";
import icon7 from "../../../assets/images/seo/icon7.png";
import icon8 from "../../../assets/images/seo/icon8].png";
import gif from "../../../assets/images/seo/seo2.gif";
import gif2 from "../../../assets/images/seo/seo3.gif";
import hero from "../../../assets/images/seo/seointro.webp";
import solution from "../../../assets/images/CRM-img/IT.png";
import line from "../../../assets/images/CRM-img/line.svg";
import seo1 from "../../../assets/images/seo/seoca1.png";
import seo2 from "../../../assets/images/seo/seoca2.png";
import seo3 from "../../../assets/images/seo/seoca3.png";
import seo4 from "../../../assets/images/seo/seoca4.png";
import seo5 from "../../../assets/images/seo/seoca5.png";
import seo6 from "../../../assets/images/seo/seoca6.png";
import seo7 from "../../../assets/images/seo/seoca7.png";
import seo8 from "../../../assets/images/seo/seoca8.png";
import seo9 from "../../../assets/images/seo/seoca9.png";
import seo10 from "../../../assets/images/seo/seoca10.png";
import seo11 from "../../../assets/images/seo/seoca11.png";
import seo12 from "../../../assets/images/seo/seoca12.png";
import seo13 from "../../../assets/images/seo/seoca13.png";
import seo14 from "../../../assets/images/seo/seoca14.png";
import seo15 from "../../../assets/images/seo/seoca15.png";
import seo16 from "../../../assets/images/seo/seoca16.png";
import benefit1 from "../../../assets/images/seo/seo1.svg";
import benefit2 from "../../../assets/images/seo/seo2.svg";
import benefit3 from "../../../assets/images/seo/seo3.svg";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
function index() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>SEO- TechiesGateway</title>
      </Helmet>
      <div className="seo">
        <img className="seo-circle1" src={circle2} alt="circle" />
        <img className="seo-circle2" src={rhombus} alt="rhombus" />
        <img className="seo-circle4" src={rhombus} alt="rhombus" />
        <img className="seo-circle3" src={circle1} alt="circle" />
        <div className="seo-intro">
          <div className="seo-intro-content">
            <div className="seo-intro-content-text1 seo-intro-blueText">
              SEO/ SEM
            </div>
            <div className="seo-intro-content-text2">
              Unlock the <span>power of SEO</span> and
              <span> dominate search result</span>
            </div>
            <div id="pricing-btn">Starts from ₹1000/-</div>
            <div className="seo-intro-content-text3">
              Stop hiding in the shadows of the internet. Unleash the hidden
              potential of your website and catapult to the top of search engine
              results
            </div>
            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();
              }}
            >
              <button class="seo-contact-us-button">Contact Us</button>
            </NavLink>
          </div>
          <div className="seo-intro-img">
            <img className="seo-img1" src={hero} alt="SEO" />
          </div>
        </div>
        <div className="seo-container">
          <div className="seo-design">
            <div className="seo-design-imgs">
              <img className="seo-design-img" src={gif} alt="SEO" />
            </div>
            <div className="seo-design-content">
              <div className="seo-top-design-heading ">SEO</div>
              <div className="seo-design-content-custom">
                <strong> Search Engine Optimazation</strong>
              </div>
              <div class="seo-design-underline">
                <img src={line} alt="underline" />
              </div>
              <div className="seo-design-content-description">
                It's like building a bridge between your website and people
                searching for what you offer. The better the bridge, the more
                people will find you. It's like putting your store on the
                busiest street corner in town, with bright lights and inviting
                signs. Everyone will know you're there and want to come in!
              </div>
              <div className="seo-design-icons">
                <div className="seo-design-icon">
                  <img src={icon1} alt="tech" />
                </div>
                <div className="seo-design-icon">
                  <img src={icon2} alt="tech" />
                </div>
                <div className="seo-design-icon">
                  <img src={icon3} alt="tech" />
                </div>
                <div className="seo-design-icon">
                  <img src={icon4} alt="tech" />
                </div>
                <div className="seo-design-icon">
                  <img src={icon5} alt="tech" />
                </div>
              </div>
              <NavLink
                to="/contact"
                onClick={() => {
                  gotoTop();
                }}
              >
                <button class="seo-contact-us-button">Contact Us</button>
              </NavLink>
            </div>
          </div>
          <div className="seo-marketing">
            <div className="seo-marketing-content">
              <div className="seo-top-marketing-heading ">SEM</div>
              <div className="seo-marketing-content-custom">
                <strong> Search Engine Marketing</strong>
              </div>
              <div class="seo-marketing-underline">
                <img src={line} alt="underline" />
              </div>
              <div className="seo-marketing-content-description">
                It is a digital marketing strategy that helps businesses
                increase their visibility in search engine results pages
                (SERPs). Unlike SEO, which focuses on long-term organic ranking
                improvements, SEM utilizes paid advertising to get your website
                seen by more people.
              </div>
              <div className="seo-marketing-icons">
                <div className="seo-marketing-icon">
                  <img src={icon5} alt="tech" />
                </div>
                <div className="seo-marketing-icon">
                  <img src={icon6} alt="tech" />
                </div>
                <div className="seo-marketing-icon">
                  <img src={icon7} alt="tech" />
                </div>
                <div className="seo-marketing-icon">
                  <img src={icon8} alt="tech" />
                </div>
              </div>
              <NavLink
                to="/contact"
                onClick={() => {
                  gotoTop();
                }}
              >
                <button class="seo-contact-us-button">Contact Us</button>
              </NavLink>
            </div>
            <div className="seo-marketing-imgs">
              <img className="seo-marketing-img" src={gif2} alt="SEO" />
            </div>
          </div>
          <div className="seo-category">
            <div className="seo-category-top">
              <p className="seo-top-heading">Category</p>
              <p class="seo-top-custom ">
                <strong>Categories of SEO & SEM</strong>
              </p>
              <div class="seo-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="seo-category-boxes seo-boxes">
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo1} alt="categories" />
                </div>
                <div className="seo-category-box-text">On-Page SEO</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo2} alt="categories" />
                </div>
                <div className="seo-category-box-text">Off-Page SEO</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo3} alt="categories" />
                </div>
                <div className="seo-category-box-text">Technical SEO</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo4} alt="categories" />
                </div>
                <div className="seo-category-box-text">Local SEO</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo5} alt="categories" />
                </div>
                <div className="seo-category-box-text">E-commerce SEO</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo6} alt="categories" />
                </div>
                <div className="seo-category-box-text">Voice Search SEO</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo7} alt="categories" />
                </div>
                <div className="seo-category-box-text">
                  Paid Search Advertising
                </div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo8} alt="categories" />
                </div>
                <div className="seo-category-box-text">Display Advertising</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo9} alt="categories" />
                </div>
                <div className="seo-category-box-text">
                  Social Media Advertising
                </div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo10} alt="categories" />
                </div>
                <div className="seo-category-box-text">
                  Remarketing/ Retargeting
                </div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo11} alt="categories" />
                </div>
                <div className="seo-category-box-text">Shopping Ads</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo12} alt="categories" />
                </div>
                <div className="seo-category-box-text">Mobile Advertising</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo13} alt="categories" />
                </div>
                <div className="seo-category-box-text">
                  App Store Optimization
                </div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo14} alt="categories" />
                </div>
                <div className="seo-category-box-text">Video Marketing</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo15} alt="categories" />
                </div>
                <div className="seo-category-box-text">Local Search Ads</div>
              </div>
              <div className="seo-category-box">
                <div className="seo-category-box-img">
                  <img src={seo16} alt="categories" />
                </div>
                <div className="seo-category-box-text">Affiliate Marketing</div>
              </div>
            </div>
          </div>
          <div className="seo-benifits">
            <div className="seo-benifits-top">
              <h2 className="seo-top-heading ">Benifits</h2>
              <p class="seo-top-custom">
                <strong>Benifits you will get</strong>
              </p>
              <div class="seo-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="seo-boxes">
              <div className="seo-benifits-box">
                <div className="seo-benifits-box-top">
                  <img src={benefit1} alt="benefits" />
                  <div className="seo-benifits-box-top-heading">
                    Increased Traffic
                  </div>
                </div>
                <div className="seo-benifits-box-content">
                  Optimizing your website for search engines brings more organic
                  traffic.
                </div>
              </div>
              <div className="seo-benifits-box">
                <div className="seo-benifits-box-top">
                  <img src={benefit2} alt="benefits" />
                  <div className="seo-benifits-box-top-heading">
                    Optimized SEO
                  </div>
                </div>
                <div className="seo-benifits-box-content">
                  To improve your organic search ranking and attract more
                  visitors, optimize your website with relevant keywords,
                  high-quality content.
                </div>
              </div>
              <div className="seo-benifits-box">
                <div className="seo-benifits-box-top">
                  <img src={benefit2} alt="benefits" />
                  <div className="seo-benifits-box-top-heading">
                    Faster Ranking
                  </div>
                </div>
                <div className="seo-benifits-box-content">
                  Prioritize content targeting long-tail keywords with low
                  competition for faster search engine ranking.
                </div>
              </div>
              <div className="seo-benifits-box">
                <div className="seo-benifits-box-top">
                  <img src={benefit3} alt="benefits" />
                  <div className="seo-benifits-box-top-heading">
                    Organic Growth
                  </div>
                </div>
                <div className="seo-benifits-box-content">
                  Craft compelling content, optimize for relevant keywords, and
                  engage your audience to cultivate sustainable organic growth.
                </div>
              </div>
            </div>
          </div>
          <div className="seo-solutions">
            <div className="seo-solutions-top">
              <h2 className="seo-top-heading blueText">
                Performance Parameter
              </h2>
              <p class="seo-top-custom">
                <strong>IT Solutions You Trust</strong>
              </p>
              <div class="seo-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="seo-boxes ">
              <div className="seo-solutions-box">
                <div className="seo-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="seo-solutions-box-heading">
                  Enhance Security
                </div>
                <div className="seo-solutions-box-content">
                  Enhanced website security <br /> protects sensitive data,
                  <br />
                  safeguards against cyber
                  <br /> threats, and fosters user trust.
                </div>
              </div>
              <div className="seo-solutions-box">
                <div className="seo-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="seo-solutions-box-heading">Reliability</div>
                <div className="seo-solutions-box-content">
                  It is the ability of a website to function as intended,
                  providing users with a seamless and dependable experience.
                </div>
              </div>
              <div className="seo-solutions-box">
                <div className="seo-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="seo-solutions-box-heading">Faster Loading</div>
                <div className="seo-solutions-box-content">
                  It enhances user experience
                  <br /> by reducing page load
                  <br /> times and improving website responsiveness.
                </div>
              </div>
              <div className="seo-solutions-box">
                <div className="seo-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="seo-solutions-box-heading">Scalability</div>
                <div className="seo-solutions-box-content">
                  It ensures a seo's ability to handle increasing traffic and
                  data demands without compromising performance.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
