import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/Tglogo.png";
import underline from "../../assets/images/underline.svg";
import linkedin from "../../assets/images/linkedin.png";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import insta from "../../assets/images/instagram.png";
import location from "../../assets/images/location.png";
import email from "../../assets/images/email.png";
import contact from "../../assets/images/contact.png";
function Footer() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="foot1">
        <div className="footNav footDisc">
          <NavLink
            to="/home"
            onClick={() => {
              gotoTop();
            }}
          >
            <img src={logo} alt="logo" />
          </NavLink>
          <p>
            We work with a passion of taking challenges and creating new ones in
            advertising sector.
            <br />
            <br />
            Managed By <b>TGXIT Services Pvt. Ltd.</b>
          </p>
        </div>
        <div className="footNav">
          <h3 className="footHead">Service</h3>
          <img src={underline} alt="line" />

          <NavLink
            to="/services/webdev"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Web Development
          </NavLink>
          <NavLink
            to="/services/app"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            App Development
          </NavLink>
          <NavLink
            to="/services/seo"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Search Engine Optimization
          </NavLink>
          <NavLink
            to="/services/uiux"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Graphics Design
          </NavLink>
          <NavLink
            to="#"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Web Hosting
          </NavLink>
          <NavLink
            to="#"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Content Writer
          </NavLink>
          <NavLink
            to="/training-program"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Training Program
          </NavLink>
        </div>
        <div className="footNav">
          <h3 className="footHead">Important Link</h3>
          <img src={underline} alt="line" />

          <NavLink
            to="/about"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            About Us
          </NavLink>
          <NavLink
            to="/contact"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Contact Us
          </NavLink>
          <NavLink
            to="/TermsAndConditions"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Term & Condition
          </NavLink>

          <NavLink
            to="/PrivacyPolicy"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Privacy Policy
          </NavLink>
          <NavLink
            to="/payment"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Payment
          </NavLink>
          {/* <NavLink
            to="/RefundPolicy"
            className="footItem"
            onClick={() => {
              gotoTop();
            }}
          >
            Refund Policy
          </NavLink> */}
        </div>
        <div className="footNav officialInfo">
          <h3 className="footHead">Official Info</h3>
          <img src={underline} alt="line" />

          <NavLink to="#" className="footItem">
            <img src={location} alt="location" />
            Behind Gajraj Tata Motors, Near Vinoba Bhave University,Sindoor,
            Hazaribagh, Jharkhand 825301
          </NavLink>
          <NavLink to="#" className="footItem">
            <img src={contact} alt="contact-icon" />
            +91 9294916452
          </NavLink>
          <NavLink to="mailto:techiesgateway@gmail.com" className="footItem">
            <img src={email} alt="email-icon" />
            techiesgateway@gmail.com
          </NavLink>
          <NavLink to="mailto:info@techiesgateway.com" className="footItem">
            <img src={email} alt="email-icon" />
            info@techiesgateway.com
          </NavLink>
          <div className="footSocial">
            <a
              href="https://www.linkedin.com/company/techies-gateway/
"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <img src={linkedin} alt="linkedin" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61556968121199&mibextid=ZbWKwL">
              {" "}
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://x.com/techiesgateway?s=09
"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <img src={twitter} alt="twitter" />
            </a>
            <a href="https://www.instagram.com/techiesgateway_?igsh=ejFtMDFtb2xwdmI3">
              {" "}
              <img src={insta} alt="insta" />
            </a>
          </div>
        </div>
      </div>
      <div className="foot2">
        © {currentYear} Techies Gateway. All Rights Reserved.
      </div>
    </div>
  );
}

export default Footer;
