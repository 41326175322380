import React from 'react'
import PrivacyPolicy from "../PrivacyPolicy/"

function index() {
  return (
    <div>
      <PrivacyPolicy/>
    </div>
  )
}

export default index