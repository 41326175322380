import React from "react";
import circle1 from "../../assets/images/contact-page/semicircle1.png";
import rhombus from "../../assets/images/contact-page/rombhus.png";
import pay from "../../assets/images/contact-page/valet.png";
import line from "../../assets/images/CRM-img/line.svg";
import ico1 from "../../assets/images/contact-page/form-person.svg";
import ico2 from "../../assets/images/contact-page/form-email.svg";
import ico3 from "../../assets/images/contact-page/form-no.svg";
import contact from "../../assets/images/contact-page/form.png";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Payment = () => {
  let { id, note } = useParams();
  console.log(id);
  console.log(note);
  const conditionalStyles =
    id !== undefined
      ? {
          border: "1px solid #8fbceb",
          borderRadius: "5px",
          color: "#1c1e53",
          padding: "12px 0",
          marginTop: "5px",
          width: "97%",
          backgroundColor: "rgb(249 249 249)",
        }
      : {};
  const conditionalNoteStyles =
    note !== undefined
      ? {
          border: "1px solid #8fbceb",
          borderRadius: "5px",
          color: "#1c1e53",
          padding: "12px 0",
          marginTop: "15px",
          width: "97%",
          backgroundColor: "rgb(249 249 249)",
        }
      : {
          marginTop: "5px",
        };
  return (
    <>
      <Helmet>
        <title>Secure Payments - TechiesGateway</title>
      </Helmet>
      <div className="payment-form">
        <div className="main-img">
          <div className="main-imgText">Techies Gateway Payment Form</div>
          <img className="pay-circle" src={circle1} alt="circle" />
        </div>
        <div id="contact-form" className="payment">
          <img className="circle2" src={rhombus} alt="circle" />
          <div className="form-div">
            <form action="/pay.php" method="POST">
              <div className="formHeading">
                <h3>Payment Form</h3>
                <p>Smooth and Secure Payments</p>
                <img src={line} alt="underline" className="underline" />
              </div>
              <div className="form-box">
                <img src={ico1} alt="#" />
                <input
                  type="text"
                  placeholder="Full Name"
                  name="name"
                  className="box-A"
                  required
                />

                <div className="last-name">
                  <img src={ico3} alt="#" style={{ marginRight: "15px" }} />
                  <input
                    className="box-A"
                    type="tel"
                    id="phone"
                    maxLength={10}
                    name="phone"
                    placeholder="Phone No."
                    required
                  />
                </div>
              </div>
              <div className="form-box">
                <img src={ico2} alt="#" />
                <input
                  type="email"
                  name="email"
                  style={{ padding: "15px 15px 15px 45px" }}
                  placeholder="Email Address"
                  required
                />
              </div>

              <div className="form-box" style={conditionalStyles}>
                <img src={pay} alt="#" />
                {id == undefined ? (
                  <input
                    type="tel"
                    name="amount"
                    placeholder="Amount"
                    value={id}
                    required
                  />
                ) : (
                  <>
                    <span
                      style={{ visibility: "hidden", position: "absolute" }}
                    >
                      <input
                        style={{ all: "unset", padding: "5px 0" }}
                        type="tel"
                        name="amount"
                        placeholder="Amount"
                        value={id}
                        required
                      />
                    </span>
                    <span style={{ position: "relative", left: "45px" }}>
                      {id}
                    </span>
                  </>
                )}
              </div>
              <div className="form-box" style={conditionalNoteStyles}>
                <img src={pay} alt="#" />
                {note == undefined ? (
                  <input
                    type="text"
                    name="note"
                    style={{ padding: "15px 15px 15px 45px" }}
                    placeholder="Add Note"
                    value={note}
                    required
                  />
                ) : (
                  <>
                    <span
                      style={{ visibility: "hidden", position: "absolute" }}
                    >
                      <input
                        style={{
                          all: "unset",
                          padding: "15px 15px 15px 45px",
                        }}
                        type="text"
                        name="note"
                        placeholder="Add Note"
                        value={note}
                        required
                      />
                    </span>
                    <span style={{ position: "relative", left: "45px" }}>
                      {note}
                    </span>
                  </>
                )}
              </div>
              <button type="submit" className="contactInfoBtn">
                Proceed
              </button>
            </form>
            <div className="form-image">
              <img src={contact} alt="contact here" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
