import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import img1 from "../assets/images/student-reg/1.png";
import img2 from "../assets/images/student-reg/2.png";
import img3 from "../assets/images/student-reg/3.png";
import img4 from "../assets/images/student-reg/4.png";
import img5 from "../assets/images/student-reg/5.png";
import img6 from "../assets/images/student-reg/6.png";
import img7 from "../assets/images/student-reg/7.png";
import { Helmet } from "react-helmet";

function StudentCourseRegistration() {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  // const onAutoplayTimeLeft = (s, time, progress) => {
  //   progressCircle.current.style.setProperty("--progress", 1 - progress);
  //   progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  // };
  return (
    <div>
      <Helmet>
        <title>Course Registration - TechiesGateway</title>
      </Helmet>
      <div className="stu-carousel">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          // onAutoplayTimeLeft={onAutoplayTimeLeft}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={img1} alt="img" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img2} alt="img" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img3} alt="img" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img4} alt="img" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img5} alt="img" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img6} alt="img" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img7} alt="img" />
          </SwiperSlide>

          <div className="autoplay-progress" slot="container-end">
            {/* <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg> */}
            <span ref={progressContent}></span>
          </div>
        </Swiper>
      </div>
      <div className="form">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScVPSTy4CTR5DQyU_JlcN5DwRMRMtZRpxI-84IJOu246jdUtw/viewform?embedded=true"
          width={"100%"}
          height={"1000px"}
          frameborder="0"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default StudentCourseRegistration;
