import React from "react";
import rhombus from "../../assets/images/about/rhombus1.png";
import semicircle from "../../assets/images/about/semicircle.png";
import rectangle1 from "../../assets/images/about/Rectangle 33-01.png";
import rectangle2 from "../../assets/images/about/Rectangle 34-02.png";
import rectangle3 from "../../assets/images/about/Rectangle 35-03.png";
import underline from "../../assets/images/about/underline.svg";
import mission from "../../assets/images/about/mission.png";
import vission from "../../assets/images/about/vision.png";
import client1 from "../../assets/images/about/pic1.png";
import client2 from "../../assets/images/about/pic2.png";
import client3 from "../../assets/images/about/pic3.png";
import client4 from "../../assets/images/about/pic4.png";
import client5 from "../../assets/images/about/pic5.png";
import client6 from "../../assets/images/about/pic6.png";
import vector from "../../assets/images/about/Vector.png";
import strength from "../../assets/images/about/strength.png";
import { Helmet } from "react-helmet";
function About() {
  return (
    <div>
      <Helmet>
        <title>About Us - TechiesGateway</title>
      </Helmet>
      <div className="hero">
        <div className="hero-content">
          <img className="rhombus" src={rhombus} alt="rhombus-img" />
          <img src={semicircle} alt="semicircle" className="semicircle1" />
          <div className="img-texts">
            <p className="img-text1">Our Team</p>
            <div className="img-text2">
              <p>
                <span className="span1">Best Digital {""}</span>
                <span className="span2">Technology</span>
              </p>{" "}
              <p>
                {" "}
                <span className="span2"> Agency</span>
              </p>
            </div>
            <div className="img-text3">
              <p>
                <span>We help business to increase their</span> revenue{" "}
              </p>
              <p>using technology.</p>
            </div>
            <button className="img-btn heading-blue">Get more info</button>
          </div>
        </div>
      </div>
      <div className="main">
        <div className="rhombus2">
          <img src={rectangle1} alt="shape" />
        </div>
        <div className="rhombus3">
          <img src={rectangle2} alt="shape" />
        </div>
        <div className="rhombus4">
          <img src={rectangle3} alt="shape" />
        </div>
        <div className="section1">
          <p className="section1-text1 heading-blue">Who are we ?</p>
          <div className="section-text2">
            <p>Your Trusted Guide to Online Success</p>
          </div>
          <div className="section-line">
            <img src={underline} alt="underline" />
          </div>
          <p className="section1-text3 text">
            We are a team of experienced professionals with a passion for
            helping businesses succeed online. We understand the challenges that
            businesses face in today's digital world, and we are committed to
            providing them with the solutions they need to succeed.
          </p>
        </div>
        <div className="section2">
          <div className="section2-content">
            <div className="section-text1 heading-blue">
              <p>Mission</p>
            </div>
            <div className="section-text2">
              <p>We are helping to grow your business</p>
            </div>
            <div className="section-line">
              <img src={underline} alt="underline" />
            </div>
            <p className="section2-text3 text">
              We are committed to crafting distinct online identities for our
              clients through innovative and bespoke digital strategies. We
              prioritize our clients needs and deliver customized solutions that
              align with their unique requirements, time-frames and budgets. Our
              unwavering customer-centric approach fuels our drive to exceed
              expectations and create user-friendly solutions that nurture
              business growth and expand audience reach to increase your digital
              presence.
            </p>
            <button class="about-getinfo-btn">Get more info</button>
          </div>
          <div className="section2-img">
            <img src={mission} alt="mission" />
          </div>
        </div>
        <div className="section3">
          <div className="section3-img">
            <img src={vission} alt="vission" />
          </div>
          <div className="section3-content">
            <div className="section3-text1 heading-blue">
              <p>Vision</p>
            </div>
            <div className="section-text2">
              <p>We are helping to grow your business</p>
            </div>
            <div className="section-line">
              <img src={underline} alt="underline" />
            </div>
            <p className="section3-text3 text">
              Our vision is anchored in the belief that technology is not merely
              a tool but a driving force for innovation and progress. We are
              committed to harnessing the power of technology to reshape
              industries, redefine customer experiences, and unlock new avenues
              for success.
            </p>
            <button class="about-getinfo-btn">Get more info</button>
          </div>
        </div>
        <div className="section4">
          <div className="section4-content">
            <div className="section4-text1 heading-blue">
              <p>Core Value</p>
            </div>
            <div className="section-text2">
              <p>
                <span className="span">Fundamental beliefs guiding</span>{" "}
                consistent actions
              </p>
            </div>
            <div className="section-line">
              <img src={underline} alt="underline" />
            </div>
          </div>
          <div className="section4-boxes">
            <div className="section4-box">
              <div className="box-head">
                <div className="box-logo">
                  <img src={vector} alt="vector" />
                </div>
                <p className="box-heading">Process Oriented</p>
              </div>
              <div className="box-info">
                <ul>
                  <li>
                    <span>Consistency : </span> A company's financial
                    performance, including its ability to generate revenue and
                    profits, is a key indicator of its value.
                  </li>
                  <li>
                    <span>Quality : </span>You are committed to delivering
                    high-quality products and services that meet or exceed
                    expectations
                  </li>
                </ul>
              </div>
            </div>
            <div className="section4-box">
              <div className="box-head">
                <div className="box-logo">
                  <img src={vector} alt="vector" />
                </div>
                <p className="box-heading">Innovation</p>
              </div>
              <div className="box-info ">
                <ul>
                  <li>
                    <span>Creativity : </span>We believe in the power of
                    creative thinking to generate new ideas and solve problems
                    in unconventional ways.
                  </li>
                  <li>
                    <span>Update : </span>We are committed to staying up-to-date
                    on the latest trends and technologies, and we are always
                    seeking new knowledge and skills.
                  </li>
                </ul>
              </div>
            </div>
            <div className="section4-box">
              <div className="box-head">
                <div className="box-logo">
                  <img src={vector} alt="vector" />
                </div>
                <p className="box-heading">Team Work</p>
              </div>
              <div className="box-info">
                <ul>
                  <li>
                    <span>Value contributions of all team members : </span>We
                    recognize that every member of the team brings unique
                    skills, perspectives and experiences to the table.
                  </li>
                  <li>
                    <span>Encourage ongoing learning and development : </span>We
                    support the growth and development by providing
                    opportunities for training and skill development.
                  </li>
                </ul>
              </div>
            </div>
            <div className="section4-box">
              <div className="box-head">
                <div className="box-logo">
                  <img src={vector} alt="vector" />
                </div>
                <p className="box-heading">Accountability</p>
              </div>
              <div className="box-info">
                <ul>
                  <li>
                    <span>Transparency and openners : </span>We are transparent
                    in our communication and decision-making, and you encourage
                    open and honest feedback.
                  </li>
                  <li>
                    <span>Follow through on commitments : </span>We make
                    promises and keep them, and we are reliable and dependable
                    in our work.
                  </li>
                </ul>
              </div>
            </div>
            <div className="section4-box">
              <div className="box-head">
                <div className="box-logo">
                  <img src={vector} alt="vector" />
                </div>
                <p className="box-heading">Passionate</p>
              </div>
              <div className="box-info">
                <ul>
                  <li>
                    <span>Deep-rooted Passion for Technology : </span>Foster a
                    culture where employees are genuinely excited about the
                    latest technological advancements.
                  </li>
                  <li>
                    <span>Enthusiams for Problem-Solving : </span>Encourage
                    employees to approach challenges with a positive mindset,
                    seeking innovative solutions that leverage technology to
                    address real-world problems.
                  </li>
                </ul>
              </div>
            </div>
            <div className="section4-box">
              <div className="box-head">
                <div className="box-logo">
                  <img src={vector} alt="vector" />
                </div>
                <p className="box-heading">Customer Value</p>
              </div>
              <div className="box-info">
                <ul>
                  <li>
                    <span>Customer-Centric Approach : </span>We prioritize
                    understanding customer needs and aspirations through market
                    research, customer feedback, and direct interactions.
                  </li>
                  <li>
                    <span>Technology-Driven Solutions : </span>We Utilize
                    technology to address customer pain points, enhance their
                    experiences, and deliver value-added services.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section5">
          <p className="section5text1 heading-blue">Client</p>
          <div className="section-text2">
            <p>Our happy Clients</p>
          </div>
          <div className="section-line">
            <img src={underline} alt="underline" />
          </div>
          <div className="section5-boxes">
            <div className="section5-box ">
              <img src={client1} alt="client" />
            </div>
            <div className="section5-box">
              <img src={client2} alt="client" />
            </div>
            <div className="section5-box">
              <img src={client3} alt="client" />
            </div>
            <div className="section5-box box-none">
              <img src={client4} alt="client" />
            </div>
            <div className="section5-box box-none">
              <img src={client5} alt="client" />
            </div>
            <div className="section5-box box-none">
              <img src={client6} alt="client" />
            </div>
          </div>
        </div>
        <div className="section6">
          <div className="section6-content">
            <p className="section6-text1 heading-blue">Core Strength</p>
            <div className="section-text2">
              <p>What are the strength of team</p>
            </div>
            <div className="section-line">
              <img src={underline} alt="underline" />
            </div>
          </div>
          <div className="section6-img">
            <img src={strength} alt="strength" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
