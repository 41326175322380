import React from "react";
import Contact from "../../components/Contact/Contact";
import { Helmet } from "react-helmet";

function index() {
  return (
    <>
      <Helmet>
        <title>Contact Us - TechiesGateway</title>
      </Helmet>
      <Contact />
    </>
  );
}

export default index;
