import "./App.scss";
// import Home from "./Pages/Home/Home";
import Footer from "./components/Footer/Footer";

import CRM from "./components/CRM/crm";

import Navbar from "./components/Navbar";
import Home from "./Pages/Home/Home.jsx";
import SMS from "./Pages/Solutions/SMS";
import HRM from "./Pages/Solutions/HRM";
import CMS from "./Pages/Solutions/CMS/cms.js";
import HMS from "./Pages/Solutions/HMS";
import LMS from "./Pages/Solutions/LMS";
import WebDev from "./Pages/Services/WebDev";
import AppDev from "./Pages/Services/AppDev";
import Uiux from "./Pages/Services/UI";
import SEO from "./Pages/Services/Seo";
import Contact from "./Pages/Contact";
import About from "./components/About/About.jsx";
import TermsAndConditions from "./Pages/Legalinfo/TermsConditon";
import PrivacyPolicy from "./Pages/Legalinfo/PrivacyPolicy";
import RefundPolicy from "./Pages/Legalinfo/RefundPolicy";
import Intern from "./Pages/Internship/Intern.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Payment from "./Pages/Payment/index.js";

import StudentRegistration from "./Pages/StudentRegistration.jsx";
import StudentCourseRegistration from "./Pages/StudentCourseRegistration.jsx";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/student-registration" element={<StudentRegistration />} />
        <Route
          path="/student-course-registration"
          element={<StudentCourseRegistration />}
        />

        <Route path="/home" element={<Home />} />
        <Route path="/solutions/sms" element={<SMS />} />
        <Route path="/solutions/crm" element={<CRM />} />
        <Route path="/solutions/hrm" element={<HRM />} />
        <Route path="/solutions/cms" element={<CMS />} />
        <Route path="/solutions/hms" element={<HMS />} />
        <Route path="/solutions/lms" element={<LMS />} />
        <Route path="/services/webdev" element={<WebDev />} />
        <Route path="/services/app" element={<AppDev />} />
        <Route path="/services/uiux" element={<Uiux />} />
        <Route path="/services/seo" element={<SEO />} />

        <Route path="/payment" element={<Payment />} />
        <Route path="/payment/:id" element={<Payment />} />
        <Route path="/payment/:id/:note" element={<Payment />} />

        <Route path="/about" element={<About />} />
        <Route path="/training-program" element={<Intern />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/RefundPolicy" element={<RefundPolicy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
export default App;
