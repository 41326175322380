import React, { useEffect } from "react";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "./Navbar.scss";
import HomeIcon from "@mui/icons-material/Home";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import InfoIcon from "@mui/icons-material/Info";
import AddchartIcon from "@mui/icons-material/Addchart";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ErrorIcon from "@mui/icons-material/Error";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { NavLink } from "react-router-dom";
import solution1 from "../assets/images/Navbar/store.png";
import solution2 from "../assets/images/Navbar/crm.png";
import solution3 from "../assets/images/Navbar/cms.png";
import solution4 from "../assets/images/Navbar/lms.png";
import solution5 from "../assets/images/Navbar/hms.png";
import solution6 from "../assets/images/Navbar/hrm.png";
import service1 from "../assets/images/Navbar/web.png";
import service2 from "../assets/images/Navbar/ui.png";
import service3 from "../assets/images/Navbar/app.png";
import service4 from "../assets/images/Navbar/seo.png";
import logo from "../assets/images/Navbar/logo (2).webp";
import x from "../assets/images/Navbar/twitter-icon.png";
import fb from "../assets/images/Navbar/facebook-icon.png";
import insta from "../assets/images/Navbar/insta-icon.png";
import ln from "../assets/images/Navbar/linkedin-icon.png";
import close from "../assets/images/Navbar/close-icon.png";
import { Facebook } from "@mui/icons-material";
import { findByRole } from "@testing-library/react";
function Navbar() {
  const [click, setClick] = useState(false);
  const toggleMobileMenu = () => {
    setClick(!click);
    console.log("show menu");
  };
  const gototop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const hoverSolutions = () => {
    document.querySelector("#solutions-dropdown").style.display = "flex";
  };
  const hoverOutSolutions = () => {
    document.querySelector("#solutions-dropdown").style.display = "none";
  };
  const hoverServices = () => {
    document.querySelector("#services-dropdown").style.display = "flex";
  };
  const hoverOutServices = () => {
    document.querySelector("#services-dropdown").style.display = "none";
  };
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <nav className={`header ${scrolled ? "scrolled" : ""}`}>
        <div className={click ? "mob-blurbg active" : "mob-blurbg"}></div>
        <div className="nav-left">
          <NavLink to="/">
            {" "}
            <img
              src={logo}
              alt="logo"
              style={{ width: 135 }}
              onClick={gototop}
            />
          </NavLink>
        </div>

        <div className={click ? "nav-right active" : "nav-right"}>
          <ul>
            <div
              className={
                click ? "upper-mobile-navbar active" : "upper-mobile-navbar"
              }
            >
              <img src={logo} alt="logo" />
              <img src={close} alt="close" onClick={toggleMobileMenu} />
            </div>
            <NavLink
              to="/"
              onClick={() => {
                gotoTop();
              }}
              activeClassName="activeLink"
            >
              <div onClick={toggleMobileMenu} className="navbar-links">
                <HomeIcon className="icon" />
                <li>Home</li>
              </div>
            </NavLink>
            <NavLink>
              <div
                onMouseOver={hoverSolutions}
                onMouseOut={hoverOutSolutions}
                className="navbar-links"
              >
                <AddchartIcon className="icon" />
                <li>Solutions</li>
                <KeyboardArrowDownSharpIcon
                  style={{ color: "rgba(139, 139, 139, 1)" }}
                />
              </div>
              <div
                className="multi-dropdown"
                id="solutions-dropdown"
                onMouseOver={hoverSolutions}
                onMouseOut={hoverOutSolutions}
              >
                <ul>
                  <div className="left">
                    <NavLink
                      to="/solutions/sms"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu} className="effect">
                        <img src={solution1} alt="SMS" />
                        Store management System
                      </li>
                    </NavLink>
                    <NavLink
                      to="/solutions/crm"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu}>
                        <img src={solution2} alt="CRM" />
                        Customer Relationship Management{" "}
                      </li>
                    </NavLink>
                    <NavLink
                      to="/solutions/hrm"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu}>
                        <img src={solution3} alt="HRMS" />
                        Human Resource management System
                      </li>
                    </NavLink>
                  </div>
                  <div className="right">
                    <NavLink
                      to="/solutions/cms"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu}>
                        <img src={solution4} alt="CMS" />
                        Content management System
                      </li>
                    </NavLink>
                    <NavLink
                      to="/solutions/hms"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu}>
                        <img src={solution5} alt="HMS" />
                        Hospital management System
                      </li>
                    </NavLink>
                    <NavLink
                      to="/solutions/lms"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu}>
                        <img src={solution6} alt="LMS" />
                        Learning management System
                      </li>
                    </NavLink>
                  </div>
                </ul>
              </div>
            </NavLink>
            <NavLink>
              <div
                onMouseOver={hoverServices}
                onMouseOut={hoverOutServices}
                className="navbar-links"
              >
                <HomeRepairServiceIcon className="icon" />
                <li>Services</li>
                <KeyboardArrowDownSharpIcon
                  style={{ color: "rgba(139, 139, 139, 1)" }}
                />
              </div>
              <div
                className="multi-dropdown"
                id="services-dropdown"
                onMouseOver={hoverServices}
                onMouseOut={hoverOutServices}
              >
                <ul>
                  <div className="left">
                    <NavLink
                      to="/services/webdev"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu}>
                        <img src={service1} alt="Web" />
                        Web Development
                      </li>
                    </NavLink>
                    <NavLink
                      to="/services/uiux"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu}>
                        <img src={service2} alt="UI/UX" />
                        Designing (Graphic & UI/UX){" "}
                      </li>
                    </NavLink>
                  </div>
                  <div className="right">
                    <NavLink
                      to="/services/app"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu}>
                        <img src={service3} alt="App" />
                        App Development
                      </li>
                    </NavLink>
                    <NavLink
                      to="/services/seo"
                      onClick={() => {
                        gotoTop();
                      }}
                    >
                      <li onClick={toggleMobileMenu}>
                        <img src={service4} alt="SEO" />
                        SEO/SEM
                      </li>
                    </NavLink>
                  </div>
                </ul>
              </div>
            </NavLink>
            <NavLink
              to="/about"
              onClick={() => {
                gotoTop();
              }}
              className="navbar-links"
            >
              <div onClick={toggleMobileMenu}>
                <ErrorIcon className="icon" />
                <li>About Us</li>
              </div>
            </NavLink>
            <NavLink
              to="/training-program"
              onClick={() => {
                gotoTop();
              }}
              className="navbar-links"
            >
              <div onClick={toggleMobileMenu}>
                <ErrorIcon className="icon" />
                <li>Training </li>
              </div>
            </NavLink>

            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();
              }}
            >
              <div className="mob-navitem" onClick={toggleMobileMenu}>
                <ContactMailIcon className="icon" />
                <li>Contact Us</li>
              </div>
            </NavLink>

            <div className="mob-navlinks">
              <NavLink
                to="/PrivacyPolicy"
                onClick={() => {
                  gotoTop();
                }}
              >
                <div className="mob-navitem" onClick={toggleMobileMenu}>
                  <AssignmentOutlinedIcon className="icon" />
                  <li>Privacy Policy</li>
                </div>
              </NavLink>
              <NavLink
                to="/TermsAndConditions"
                onClick={() => {
                  gotoTop();
                }}
              >
                <div
                  className="mob-navitem nanbar-links"
                  onClick={toggleMobileMenu}
                >
                  <ErrorIcon className="icon" />
                  <li>Term and Conditions</li>
                </div>
              </NavLink>
              <NavLink
                to="/RefundPolicy"
                onClick={() => {
                  gotoTop();
                }}
              >
                <div className="mob-navitem" onClick={toggleMobileMenu}>
                  <ArticleOutlinedIcon className="icon" />
                  <li>Refund Policy</li>
                </div>
              </NavLink>
              <li className="mob-navconnect">Connect</li>
              <div className="mob-nav-mediaitem">
                <a
                  href="http://www.linkedin.com/company/techies-gateway/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ln} alt="linkedIn" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61556968121199&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fb} alt="facebook" />
                </a>
                <a
                  href="http://www.instagram.com/techiesgateway_?igsh=ejFtMDFtb2xwdmI3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={insta} alt="instagram" />
                </a>
                <a
                  href="https://x.com/techiesgateway?s=09"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={x} alt="twitter" />
                </a>
              </div>
            </div>
          </ul>
          <NavLink
            to="/contact"
            onClick={() => {
              gotoTop();
            }}
          >
            <button className="contact-btn">Contact Us</button>
          </NavLink>
        </div>
        <MenuIcon className="menu-icon" onClick={toggleMobileMenu} />
      </nav>
    </>
  );
}

export default Navbar;
