import React from "react";
import circle1 from "../../../assets/images/web-dev/web-circle.png";
import circle2 from "../../../assets/images/web-dev/webcircle2.png";
import rhombus from "../../../assets/images/web-dev/web-rectangle1.png";
import icon1 from "../../../assets/images/ui-img/uiIcon1.svg";
import icon2 from "../../../assets/images/ui-img/uiIcon2.svg";
import icon3 from "../../../assets/images/ui-img/uiIcon3.svg";
import icon4 from "../../../assets/images/ui-img/uiIcon4.svg";
import icon5 from "../../../assets/images/ui-img/uiIcon5.svg";
import icon6 from "../../../assets/images/ui-img/uiIcon6.svg";
import gif1 from "../../../assets/images/ui-img/gif1.gif";
import hero from "../../../assets/images/web-dev/introimg.svg";
import gif2 from "../../../assets/images/ui-img/gif2.gif";
import line from "../../../assets/images/CRM-img/line.svg";
import seo1 from "../../../assets/images/seo/seoca1.png";
import seo2 from "../../../assets/images/seo/seoca2.png";
import seo3 from "../../../assets/images/seo/seoca3.png";
import seo4 from "../../../assets/images/seo/seoca4.png";
import seo5 from "../../../assets/images/seo/seoca5.png";
import seo6 from "../../../assets/images/seo/seoca6.png";
import seo7 from "../../../assets/images/seo/seoca7.png";
import seo8 from "../../../assets/images/seo/seoca8.png";
import seo9 from "../../../assets/images/seo/seoca9.png";
import seo10 from "../../../assets/images/seo/seoca10.png";
import seo11 from "../../../assets/images/seo/seoca11.png";
import seo12 from "../../../assets/images/seo/seoca12.png";
import seo13 from "../../../assets/images/seo/seoca13.png";
import seo14 from "../../../assets/images/seo/seoca14.png";
import seo15 from "../../../assets/images/seo/seoca15.png";
import seo16 from "../../../assets/images/seo/seoca16.png";
import benefit1 from "../../../assets/images/seo/seo1.png";
import benefit2 from "../../../assets/images/seo/seo2.svg";
import benefit3 from "../../../assets/images/seo/seo3.png";
import project1 from "../../../assets/images/web-dev/project1.webp";
import project3 from "../../../assets/images/web-dev/project2.webp";
import project4 from "../../../assets/images/web-dev/project3.webp";
import project5 from "../../../assets/images/web-dev/project5.webp";
import project2 from "../../../assets/images/app-dev/appsample1.png";
import project6 from "../../../assets/images/app-dev/appsample2.png";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

function index() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>UI/UX - TechiesGateway</title>
      </Helmet>
      <div className="dgn">
        <img className="dgn-circle1" src={circle2} alt="circle" />
        <img className="dgn-circle2" src={rhombus} alt="rhombus" />
        <img className="dgn-circle4" src={rhombus} alt="rhombus" />
        <img className="dgn-circle3" src={circle1} alt="circle" />
        <div className="dgn-intro">
          <div className="dgn-intro-content">
            <div className="dgn-intro-content-text1 dgn-intro-blueText">
              UI/UX / Graphics Design
            </div>
            <div className="dgn-intro-content-text2">
              We create
              <br />
              <span>Modern Designs</span>
            </div>
            <div id="pricing-btn">Starts from ₹1000/-</div>
            <div className="dgn-intro-content-text3">
              We are a team of passionate designers who specialize in crafting
              modern, user-centric designs that elevate brands and enhance user
              experiences. We embrace the latest design trends and methodologies
              that meet the unique needs of our clients and their target
              audience.
            </div>
            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();
              }}
            >
              <button class="dgn-contact-us-button">Contact Us</button>
            </NavLink>
          </div>
          <div className="dgn-intro-img">
            <img className="dgn-img1" src={hero} alt="UI-UX" />
          </div>
        </div>
        <div className="dgn-container">
          <div className="dgn-design">
            <div className="dgn-design-imgs">
              <img className="dgn-design-img" src={gif1} alt="gif1" />
            </div>
            <div className="dgn-design-content">
              <div className="dgn-top-design-heading ">Designing</div>
              <div className="dgn-design-content-custom">
                <strong> UI/UX Designing</strong>
              </div>
              <div class="dgn-design-underline">
                <img src={line} alt="underline" />
              </div>
              <div className="dgn-design-content-description">
                Effective UI design is not merely about aesthetics, it's about
                crafting intuitive, engaging and user - centric experiences that
                foster seamless interaction and achieve desired outcomes.
              </div>
              <div className="dgn-design-icons">
                <div className="dgn-design-icon">
                  <img src={icon1} alt="tech1" />
                </div>
                <div className="dgn-design-icon">
                  <img src={icon2} alt="tech2" />
                </div>
                <div className="dgn-design-icon">
                  <img src={icon3} alt="tech3" />
                </div>
                <div className="dgn-design-icon">
                  <img src={icon4} alt="tech4" />
                </div>
                <div className="dgn-design-icon">
                  <img src={icon6} alt="tech5" />
                </div>
              </div>
              <NavLink
                to="/contact"
                onClick={() => {
                  gotoTop();
                }}
              >
                <button class="dgn-contact-us-button">Contact Us</button>
              </NavLink>
            </div>
          </div>
          <div className="dgn-marketing">
            <div className="dgn-marketing-content">
              <div className="dgn-top-marketing-heading ">Designing</div>
              <div className="dgn-marketing-content-custom">
                <strong> Graphics Designing</strong>
              </div>
              <div class="dgn-marketing-underline">
                <img src={line} alt="underline" />
              </div>
              <div className="dgn-marketing-content-description">
                Effective graphic design can make the difference between a
                website that blends into the background and one that captures
                attention, conveys credibility, and drives conversions.
              </div>
              <div className="dgn-marketing-icons">
                <div className="dgn-marketing-icon">
                  <img src={icon4} alt="tech" />
                </div>
                <div className="dgn-marketing-icon">
                  <img src={icon5} alt="tech" />
                </div>
                <div className="dgn-marketing-icon">
                  <img src={icon2} alt="tech" />
                </div>
                <div className="dgn-marketing-icon">
                  <img src={icon6} alt="tech" />
                </div>
              </div>
              <NavLink
                to="/contact"
                onClick={() => {
                  gotoTop();
                }}
              >
                <button class="dgn-contact-us-button">Contact Us</button>
              </NavLink>
            </div>
            <div className="dgn-marketing-imgs">
              <img className="dgn-marketing-img" src={gif2} alt="gif" />
            </div>
          </div>
          <div className="dgn-category">
            <div className="dgn-category-top">
              <p className="dgn-top-heading">Category</p>
              <p class="dgn-top-custom ">
                <strong>Categories of Designs</strong>
              </p>
              <div class="dgn-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="dgn-category-boxes dgn-boxes">
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo1} alt="web design" />
                </div>
                <div className="dgn-category-box-text">
                  Coperate website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo2} alt="web design" />
                </div>
                <div className="dgn-category-box-text">
                  Personal website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo3} alt="flyer design" />
                </div>
                <div className="dgn-category-box-text">Flyer Designs</div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo4} alt="gov. web design" />
                </div>
                <div className="dgn-category-box-text">
                  Government website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo5} alt="ecommerce web" />
                </div>
                <div className="dgn-category-box-text">Ecommerce website</div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo6} alt="web design" />
                </div>
                <div className="dgn-category-box-text">
                  Educational website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo7} alt="logo designs" />
                </div>
                <div className="dgn-category-box-text">Logo Designs</div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo8} alt="web design" />
                </div>
                <div className="dgn-category-box-text">NGO website Design</div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo9} alt="web design" />
                </div>
                <div className="dgn-category-box-text">
                  Health and wellness website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo10} alt="branding" />
                </div>
                <div className="dgn-category-box-text">Branding</div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo11} alt="restaurant website" />
                </div>
                <div className="dgn-category-box-text">
                  Restaurant website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo12} alt="blog website" />
                </div>
                <div className="dgn-category-box-text">
                  Blog/ News website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo13} alt="event web design" />
                </div>
                <div className="dgn-category-box-text">
                  Event Ticket website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo14} alt="real start web design" />
                </div>
                <div className="dgn-category-box-text">
                  Real State website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo15} alt="travel web design" />
                </div>
                <div className="dgn-category-box-text">
                  Travel and Tourism website Design
                </div>
              </div>
              <div className="dgn-category-box">
                <div className="dgn-category-box-img">
                  <img src={seo16} alt="package design" />
                </div>
                <div className="dgn-category-box-text">Package Designs</div>
              </div>
            </div>
          </div>
          <div className="dgn-benifits">
            <div className="dgn-benifits-top">
              <h2 className="dgn-top-heading ">Benifits</h2>
              <p class="dgn-top-custom">
                <strong>Benifits you will get</strong>
              </p>
              <div class="dgn-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="dgn-boxes">
              <div className="dgn-benifits-box">
                <div className="dgn-benifits-box-top">
                  <img src={benefit1} alt="benefits" />
                  <div className="dgn-benifits-box-top-heading">
                    Data Driven Design
                  </div>
                </div>
                <div className="dgn-benifits-box-content">
                  Data-driven design utilizes user data to craft intuitive and
                  effective designs.
                </div>
              </div>
              <div className="dgn-benifits-box">
                <div className="dgn-benifits-box-top">
                  <img src={benefit2} alt="benefits" />
                  <div className="dgn-benifits-box-top-heading">
                    User Friendly
                  </div>
                </div>
                <div className="dgn-benifits-box-content">
                  Easy to use and understand for all users.
                </div>
              </div>
              <div className="dgn-benifits-box">
                <div className="dgn-benifits-box-top">
                  <img src={benefit2} alt="benefits" />
                  <div className="dgn-benifits-box-top-heading">
                    Modern Design
                  </div>
                </div>
                <div className="dgn-benifits-box-content">
                  Modern UI/UX designs prioritize user-centricity,
                  accessibility, and seamless interaction.
                </div>
              </div>
              <div className="dgn-benifits-box">
                <div className="dgn-benifits-box-top">
                  <img src={benefit3} alt="benefits" />
                  <div className="dgn-benifits-box-top-heading">
                    Social Media Posts
                  </div>
                </div>
                <div className="dgn-benifits-box-content">
                  Social media posts are short, engaging pieces of content
                  designed to capture attention, spark conversations, and drive
                  action.
                </div>
              </div>
            </div>
          </div>
          <div className="dgn-samples">
            <div className="dgn-samples-top">
              <h2 className="dgn-top-heading ">Work Sample</h2>
              <p class="dgn-top-custom">
                <strong>Why you choose us</strong>
              </p>
              <div class="dgn-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="dgn-boxes">
              <div className="dgn-sample-box">
                <div className="dgn-sample-box-imgz">
                  <img src={project1} alt="projects" />
                </div>
                <div className="dgn-sample-box-contents">
                  <div className="dgn-sample-box-content1">
                    Educational website
                  </div>
                  <div className="dgn-sample-box-content2">
                    MCA Dept, Hazaribagh Jharkhand
                  </div>
                </div>
              </div>
              <div className="dgn-sample-box">
                <div className="dgn-sample-box-img">
                  <img src={project2} alt="projects" />
                </div>
                <div className="dgn-sample-box-contents">
                  <div className="dgn-sample-box-content1">E-learning App</div>
                  <div className="dgn-sample-box-content2">PRAN App</div>
                </div>
              </div>
              <div className="dgn-sample-box">
                <div className="dgn-sample-box-imgz">
                  <img src={project3} alt="projects" />
                </div>
                <div className="dgn-sample-box-contents">
                  <div className="dgn-sample-box-content1">
                    E-commerce single page Website
                  </div>
                  <div className="dgn-sample-box-content2">
                    Home Cake & Bake
                  </div>
                </div>
              </div>
              <div className="dgn-sample-box">
                <div className="dgn-sample-box-imgz">
                  <img src={project4} alt="projects" />
                </div>
                <div className="dgn-sample-box-contents">
                  <div className="dgn-sample-box-content1">
                    Portfolio Website
                  </div>
                  <div className="dgn-sample-box-content2">Rajiv Rajan</div>
                </div>
              </div>
              <div className="dgn-sample-box">
                <div className="dgn-sample-box-imgz">
                  <img src={project5} alt="projects" />
                </div>
                <div className="dgn-sample-box-contents">
                  <div className="dgn-sample-box-content1">
                    E-learning platform
                  </div>
                  <div className="dgn-sample-box-content2">PRAN</div>
                </div>
              </div>
              <div className="dgn-sample-box">
                <div className="dgn-sample-box-img">
                  <img src={project6} alt="projects" />
                </div>
                <div className="dgn-sample-box-contents">
                  <div className="dgn-sample-box-content1">Restaurant App </div>
                  <div className="dgn-sample-box-content2">MEDEV</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
