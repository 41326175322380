import React from "react";
import benefit1 from "../../../assets/images/CRM-img/benefit1.png";
import benefit2 from "../../../assets/images/CRM-img/benefit2.png";
import benefit3 from "../../../assets/images/CRM-img/benefit3.png";
import benefit4 from "../../../assets/images/CRM-img/benefit4.png";
import solution from "../../../assets/images/CRM-img/IT.png";
import circle1 from "../../../assets/images/CRM-img/circle1.png";
import circle2 from "../../../assets/images/CRM-img/circle2.png";
import rhombus from "../../../assets/images/CRM-img/rectangle2.png";
import line from "../../../assets/images/CRM-img/line.svg";
import gif from "../../../assets/images/sms-img/smsgif.gif";
import hero from "../../../assets/images/sms-img/smshero.png";
import box1 from "../../../assets/images/sms-img/smsbox1.png";
import box2 from "../../../assets/images/sms-img/smsbox2.png";
import box3 from "../../../assets/images/sms-img/smsbox3.png";
import box4 from "../../../assets/images/sms-img/smsbox4.png";
import box5 from "../../../assets/images/sms-img/smsbox5.png";
import box6 from "../../../assets/images/sms-img/smsbox6.png";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

function index() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>SMS - TechiesGateway</title>
      </Helmet>
      <div className="sms-main-img">
        <img src={hero} alt="SMS" />
        <img className="circle1" src={circle1} alt="circle" />
      </div>
      <div className="sms-container">
        <img className="circle2" src={circle1} alt="circle" />
        <img className="rectangle2" src={rhombus} alt="rhombus" />
        {/* <img className="rectangle3" src={rhombus} alt="rhombus" /> */}
        <div className="sms-intro ">
          <div className="sms-intro-content">
            <div className="sms-intro-content-text1">SMS</div>
            <div className="sms-intro-content-text2">
              We Build <span> Store Management System</span>
            </div>
            <div className="sms-intro-content-text3">
              Embark on seamless retail management with our expertise. We build
              Store Management Systems that streamline inventory, transactions,
              and operations for an efficient and organized retail experience.
              From inventory control to sales analytics, trust our Store
              Management Systems to empower your retail success.
            </div>
            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();
              }}
            >
              <button class="sms-contact-us-button">Contact Us</button>
            </NavLink>
          </div>
          <div className="sms-intro-img">
            <img className="sms-img1" src={gif} alt="SMS" />
          </div>
        </div>
        <div className="sms-service ">
          <div className="sms-service-top">
            <h2 className="sms-top-heading blueText">Service</h2>
            <p class="sms-top-custom ">
              <strong>
                <span> Elevate your digital presence </span> with our SMS
              </strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="sms-boxes">
            <div className="sms-service-box">
              <div className="sms-service-box-img">
                <img src={box1} alt="inventory management" />
              </div>
              <div className="sms-service-box-heading">
                Inventory Management
              </div>
              <div className="sms-service-box-content">
                Efficiently manage inventory with our SMS, ensuring accuracy and
                optimization for streamlined business operations.
              </div>
            </div>
            <div className="sms-service-box">
              <div className="sms-service-box-img">
                <img src={box2} alt="pos system" />
              </div>
              <div className="sms-service-box-heading">
                Point of Sale (POS) System
              </div>
              <div className="sms-service-box-content">
                Streamlined, secure, and user-friendly, it ensures efficient
                sales processes, accurate inventory management, and enhanced
                customer experiences.
              </div>
            </div>
            <div className="sms-service-box">
              <div className="sms-service-box-img">
                <img src={box3} alt="order management" />
              </div>
              <div className="sms-service-box-heading">
                {" "}
                Order and Purchase
                <br /> Management
              </div>
              <div className="sms-service-box-content">
                Efficient processes, real-time tracking, and seamless
                integration for optimized business operations and enhanced
                supply chain control.
              </div>
            </div>
            <div className="sms-service-box">
              <div className="sms-service-box-img">
                <img src={box4} alt="sales management" />
              </div>
              <div className="sms-service-box-heading">
                Sales and Customer
                <br /> Management
              </div>
              <div className="sms-service-box-content">
                From lead to conversion, our platform ensures seamless
                interactions, effective tracking, and enhanced customer
                satisfaction for business growth.
              </div>
            </div>
            <div className="sms-service-box">
              <div className="sms-service-box-img">
                <img src={box5} alt="multi-store support" />
              </div>
              <div className="sms-service-box-heading">Multi-Store Support</div>
              <div className="sms-service-box-content">
                Seamlessly manage multiple locations, ensuring centralized
                control, streamlined operations, and consistent efficiency
                across your entire retail network.
              </div>
            </div>
            <div className="sms-service-box">
              <div className="sms-service-box-img">
                <img src={box6} alt="employee tracking" />
              </div>
              <div className="sms-service-box-heading">
                Employee Performance Tracking
              </div>
              <div className="sms-service-box-content">
                Enhance workforce efficiency <br />
                with streamline evaluations, set goals, and gain insights for
                continuous improvement, fostering a high-performing and
                motivated team.
              </div>
            </div>
          </div>
        </div>
        <div className="sms-benifits">
          <div className="sms-benifits-top">
            <h2 className="sms-top-heading ">Benifits</h2>
            <p class="sms-top-custom">
              <strong>Benifits you will get</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="sms-boxes">
            <div className="sms-benifits-box">
              <div className="sms-benifits-box-top">
                <img src={benefit1} alt="benefits" />
                <div className="sms-benifits-box-top-heading">
                  <span>User-Friendly </span>
                  Interface
                </div>
              </div>
              <div className="sms-benifits-box-content">
                Intuitive interface ensures easy navigation, enhancing user
                experience in our streamlined Store Management System.
              </div>
            </div>
            <div className="sms-benifits-box ">
              <div className="sms-benifits-box-top">
                <img src={benefit2} alt="benefits" />
                <div className="sms-benifits-box-top-heading">
                  Customization and Scalability
                </div>
              </div>
              <div className="sms-benifits-box-content">
                Tailored solutions meet evolving needs. Our system ensures
                seamless customization and scalability for optimal business
                growth.
              </div>
            </div>
            <div className="sms-benifits-box">
              <div className="sms-benifits-box-top">
                <img src={benefit3} alt="benefits" />
                <div className="sms-benifits-box-top-heading">
                  Data Security and Compliance
                </div>
              </div>
              <div className="sms-benifits-box-content">
                Prioritize data security. Our system ensures compliance,
                safeguarding information with robust measures for peace of mind.
              </div>
            </div>
            <div className="sms-benifits-box">
              <div className="sms-benifits-box-top">
                <img src={benefit4} alt="benefits" />
                <div className="sms-benifits-box-top-heading">
                  Dedicated Support
                </div>
              </div>
              <div className="sms-benifits-box-content">
                Experience unwavering support. Our system ensures dedicated
                assistance, resolving issues promptly for optimal performance
                and reliability.
              </div>
            </div>
          </div>
        </div>
        <div className="sms-solutions">
          <div className="sms-solutions-top">
            <h2 className="sms-top-heading blueText">Performance Parameter</h2>
            <p class="sms-top-custom">
              <strong>IT Solutions You Trust</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="sms-boxes ">
            <div className="sms-solutions-box">
              <div className="sms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="sms-solutions-box-heading">Enhance Security</div>
              <div className="sms-solutions-box-content">
                Enhanced website security <br /> protects sensitive data,
                <br />
                safeguards against cyber
                <br /> threats, and fosters user trust.
              </div>
            </div>
            <div className="sms-solutions-box">
              <div className="sms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="sms-solutions-box-heading">Reliability</div>
              <div className="sms-solutions-box-content">
                It is the ability of a website to function as intended,
                providing users with a seamless and dependable experience.
              </div>
            </div>
            <div className="sms-solutions-box">
              <div className="sms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="sms-solutions-box-heading">Faster Loading</div>
              <div className="sms-solutions-box-content">
                It enhances user experience by reducing page load times and
                improving website
                <br /> responsiveness.
              </div>
            </div>
            <div className="sms-solutions-box">
              <div className="sms-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="sms-solutions-box-heading">Scalability</div>
              <div className="sms-solutions-box-content">
                It ensures a website's ability to handle increasing traffic and
                data demands without compromising performance.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
