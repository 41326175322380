import React from "react";
import heroimg from "../../../assets/images/cms-img/cms.webp";
import cmsgif from "../../../assets/images/cms-img/gifcms.gif";
import circle1 from "../../../assets/images/cms-img/circle1.png";
import circle2 from "../../../assets/images/cms-img/circle2.png";
import rhombus from "../../../assets/images/cms-img/rectangle2.png";
import line from "../../../assets/images/cms-img/line.svg";
import box1 from "../../../assets/images/cms-img/cmsbox1.png";
import box2 from "../../../assets/images/cms-img/cmsbox2.png";
import box3 from "../../../assets/images/cms-img/cmsbox3.png";
import box4 from "../../../assets/images/cms-img/cmsbox4.png";
import box5 from "../../../assets/images/cms-img/cmsbox5.png";
import box6 from "../../../assets/images/cms-img/cmsbox6.png";
import benefit1 from "../../../assets/images/cms-img/benefit1.png";
import benefit2 from "../../../assets/images/cms-img/benefit2.png";
import benefit3 from "../../../assets/images/cms-img/benefit3.png";
import benefit4 from "../../../assets/images/cms-img/benefit4.png";
import solution from "../../../assets/images/cms-img/IT.png";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

function cms() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>CMS - TechiesGateway</title>
      </Helmet>
      <div className="cms-main-img">
        <img src={heroimg} alt="CMS" />
        <img className="circle1" src={circle1} alt="circle" />
      </div>
      <div className="cms-container">
        <img className="circle2" src={circle2} alt="circle" />
        <img className="rectangle2" src={rhombus} alt="rhombus" />
        <div className="cms-intro ">
          <div className="cms-intro-content">
            <div className="cms-intro-content-text1">CMS</div>
            <div className="cms-intro-content-text2">
              We Build{" "}
              <span>
                {" "}
                Content Management
                <br />
                System
              </span>
            </div>
            <div className="cms-intro-content-text3">
              We build Content Management Systems (cms) that empower your
              business. Our solutions streamline content creation, enhance
              collaboration, and ensure scalable, secure, and SEO-friendly
              digital experiences.
            </div>
            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();
              }}
            >
              <button class="cms-contact-us-button">Contact Us</button>
            </NavLink>
          </div>
          <div className="cms-intro-img">
            <img className="cms-img1" src={cmsgif} alt="CMS" />
          </div>
        </div>
        <div className="cms-service ">
          <div className="cms-service-top">
            <h2 className="cms-top-heading">Service</h2>
            <p class="cms-top-custom ">
              <strong>
                <span> Elevate your digital presence</span> with our CMS
              </strong>
            </p>
            <div class="underline">
              <img src={line} alt="line" />
            </div>
          </div>
          <div className="cms-boxes">
            <div className="cms-service-box">
              <div className="cms-service-box-img">
                <img src={box1} alt="Content Creation" />
              </div>
              <div className="cms-service-box-heading">
                Content Creation and Editing
              </div>
              <div className="cms-service-box-content">
                Enable users to create, edit, and publish various content types
                easily, such as articles, blog posts, and multimedia content.
              </div>
            </div>
            <div className="cms-service-box">
              <div className="cms-service-box-img">
                <img src={box2} alt="Content Organization" />
              </div>
              <div className="cms-service-box-heading ">
                Content Organization and <br />
                Structuring
              </div>
              <div className="cms-service-box-content ">
                Organize content hierarchically, categorize, and tag content for
                easy navigation and searchability.
              </div>
            </div>
            <div className="cms-service-box">
              <div className="cms-service-box-img">
                <img src={box3} alt="Workflow Management" />
              </div>
              <div className="cms-service-box-heading">Workflow Management</div>
              <div className="cms-service-box-content">
                Facilitate collaboration among multiple users, managing content
                creation workflows, and ensuring proper approval processes.
              </div>
            </div>
            <div className="cms-service-box">
              <div className="cms-service-box-img">
                <img src={box4} alt="Performance Monitoring" />
              </div>
              <div className="cms-service-box-heading">
                Performance Monitoring and
                <br /> Analytics
              </div>
              <div className="cms-service-box-content">
                rack content performance, user engagement, and website metrics
                through analytics tools integrated with the CMS.
              </div>
            </div>
            <div className="cms-service-box">
              <div className="cms-service-box-img">
                <img src={box5} alt="Security" />
              </div>
              <div className="cms-service-box-heading">
                Security and Compliance
              </div>
              <div className="cms-service-box-content">
                Ensure data security, backups, and compliance with data
                protection regulations to safeguard sensitive information.
              </div>
            </div>
            <div className="cms-service-box">
              <div className="cms-service-box-img">
                <img src={box6} alt="Training" />
              </div>
              <div className="cms-service-box-heading">
                Training and Support
              </div>
              <div className="cms-service-box-content">
                Provide training resources and customer support to help users
                maximize the use of the CMS effectively.
              </div>
            </div>
          </div>
        </div>
        <div className="cms-benifits">
          <div className="cms-benifits-top">
            <h2 className="cms-top-heading ">Benifits</h2>
            <p class="cms-top-custom">
              <strong>Benifits you will get</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="cms-boxes">
            <div className="cms-benifits-box">
              <div className="cms-benifits-box-top">
                <img src={benefit1} alt="benefits" />
                <div className="cms-benifits-box-top-heading">
                  <span>User-Friendly </span>
                  Interface
                </div>
              </div>
              <div className="cms-benifits-box-content">
                It ensures effortless content management, enhancing
                productivity, reducing learning curves, and fostering efficient
                collaboration.
              </div>
            </div>
            <div className="cms-benifits-box ">
              <div className="cms-benifits-box-top">
                <img src={benefit2} alt="benefits" />
                <div className="cms-benifits-box-top-heading">
                  Customization and Scalability
                </div>
              </div>
              <div className="cms-benifits-box-content">
                CMS customizes content, scales with growth, optimizing user
                experiences, and future-proofing digital presence.
              </div>
            </div>
            <div className="cms-benifits-box">
              <div className="cms-benifits-box-top">
                <img src={benefit3} alt="benefits" />
                <div className="cms-benifits-box-top-heading">
                  Customization
                </div>
              </div>
              <div className="cms-benifits-box-content">
                It tailors your digital presence to unique needs, ensuring
                personalized content, seamless branding, and an engaging user
                experience.
              </div>
            </div>
            <div className="cms-benifits-box">
              <div className="cms-benifits-box-top">
                <img src={benefit4} alt="benefits" />
                <div className="cms-benifits-box-top-heading">
                  Dedicated Support
                </div>
              </div>
              <div className="cms-benifits-box-content">
                Dedicated support in a CMS ensures prompt assistance, expert
                guidance, and seamless issue resolution, maximizing user
                satisfaction and system reliability.
              </div>
            </div>
          </div>
        </div>
        <div className="cms-solutions">
          <div className="cms-solutions-top">
            <h2 className="cms-top-heading blueText">Performance Parameter</h2>
            <p class="cms-top-custom">
              <strong>IT Solutions You Trust</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="cms-boxes ">
            <div className="cms-solutions-box">
              <div className="cms-solutions-box-img">
                <img src={solution} alt="solution" />
              </div>
              <div className="cms-solutions-box-heading">Enhance Security</div>
              <div className="cms-solutions-box-content">
                Enhanced website security <br /> protects sensitive data,
                <br />
                safeguards against cyber
                <br /> threats, and fosters user trust.
              </div>
            </div>
            <div className="cms-solutions-box">
              <div className="cms-solutions-box-img">
                <img src={solution} alt="solution" />
              </div>
              <div className="cms-solutions-box-heading">Reliability</div>
              <div className="cms-solutions-box-content">
                It is the ability of a website to function as intended,
                providing users with a seamless and dependable experience.
              </div>
            </div>
            <div className="cms-solutions-box">
              <div className="cms-solutions-box-img">
                <img src={solution} alt="solution" />
              </div>
              <div className="cms-solutions-box-heading">Faster Loading</div>
              <div className="cms-solutions-box-content">
                It enhances user experience by reducing page load times and
                improving website
                <br /> responsiveness.
              </div>
            </div>
            <div className="cms-solutions-box">
              <div className="cms-solutions-box-img">
                <img src={solution} alt="solution" />
              </div>
              <div className="cms-solutions-box-heading">Scalability</div>
              <div className="cms-solutions-box-content">
                It ensures a website's ability to handle increasing traffic and
                data demands without compromising performance.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default cms;
