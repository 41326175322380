import React from "react";
// import "./home.scss";
import imgLogo from "../../assets/images/Rectangle 7.jpg";
import webDev from "../../assets/images/web development (1).webp";
import appDev from "../../assets/images/app development.webp";
import contWriting from "../../assets/images/content writing.webp";
import socialMedia from "../../assets/images/social media marketing.webp";
import graphicsDesign from "../../assets/images/graphics design.webp";
import webHosting from "../../assets/images/web hosting.webp";
import underline from "../../assets/images/underline.svg";
import image1 from "../../assets/images/image1.webp";
import image2 from "../../assets/images/image2.webp";
import image3 from "../../assets/images/image3.webp";
import image4 from "../../assets/images/image4.webp";
import image5 from "../../assets/images/image5.webp";
import image6 from "../../assets/images/image6.webp";
import underline1 from "../../assets/images/underline1.svg";
import girl from "../../assets/images/girl.webp";
import arrow from "../../assets/images/arrow.svg";
import imgPic from "../../assets/images/Rectangle 4.png";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

function Home() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>TechiesGateway - IT Solutions</title>
      </Helmet>
      <div>
        <div class="main-img">
          {/* <img class="cover-image" width="100%" src={imgPic} alt="" /> */}

          <div class="on-img-text">
            <div class="home-img-text">
              <p>Grow Business</p>
            </div>
            <div class="home-img-content">
              <p>
                Best Digital Technology <br />
                Agency
              </p>
            </div>
            <p class="img-p-content">
              We help business to increase their revenue <br />
              using technology.
            </p>
            <div class="get-info-btn">
              <NavLink to="/about">
                <button class="home-getinfo-btn">Get more info</button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="About-us-container">
          <div id="home-about-us">
            <h3 class="About-uss"> About us</h3>
            <p class="home-about-us-title">
              <strong>We are Helping to grow your business</strong>
            </p>
            <div class="underline1" style={{ paddingBottom: "10px" }}>
              <img src={underline} alt="underline" />
            </div>

            <p class="home-about-us-content">
              We are a team of experienced professionals with a passion for
              helping businesses <br /> succeed online. We understand the
              challenges that businesses face in today's <br /> digital world,
              and we are committed to providing them with the solutions <br />
              they need to succeed.
            </p>
            <NavLink to="/about">
              <button class="home-contact-us-button">Get more info</button>
            </NavLink>
          </div>

          <div class="home-about-us-img">
            <img width="100%" height="250px" src={imgLogo} alt="our team" />
          </div>
        </div>
        <div class="container">
          <h2 class="home-service-heading">Service</h2>
          <p class="home-service-custom">
            <strong>Custom IT Solution for Your Business</strong>
          </p>
          <div class="underline">
            <img src={underline} alt="underline" />
          </div>
          <section class="service">
            <div class="home-service-box">
              <div>
                <img
                  src={webDev}
                  alt="web development"
                  style={{ width: "130px" }}
                />
              </div>
              <div class="home-s-heading">Web Development</div>
              <div class="home-s-content">
                Web development involves creating & maintaining websites,
                ensuring functionality, performance, user experience & SEO
                optimization.
              </div>

              <NavLink
                to="/services/webdev"
                onClick={() => {
                  gotoTop();
                }}
              >
                <button class="home-service-button">Explore now</button>
                <img class="arrow" src={arrow} alt="arrow" />
              </NavLink>
            </div>

            <div class="home-service-box">
              <div>
                <img
                  src={appDev}
                  alt="app development"
                  style={{ width: "130px" }}
                />
              </div>
              <div class="home-s-heading">App Development</div>
              <div class="home-s-content">
                App development involves creating, building & maintaining
                application, ensuring functionality, performance, user
                experience & compatibility across devices.
              </div>
              <NavLink
                onClick={() => {
                  gotoTop();
                }}
                to="/services/app"
              >
                <button class="home-service-button">Explore now</button>
                <img class="arrow" src={arrow} alt="arrow" />
              </NavLink>
            </div>

            <div class="home-service-box">
              <div>
                <img
                  src={contWriting}
                  alt="content writing"
                  style={{ width: "130px" }}
                />
              </div>
              <div class="home-s-heading">Content Writing</div>
              <div class="home-s-content">
                Content writers research, create and edit written material for
                diverse platforms. They optimize content for SEO.
              </div>
              <NavLink
                onClick={() => {
                  gotoTop();
                }}
                to="/solutions/cms"
              >
                <button class="home-service-button">Explore now</button>
                <img class="arrow" src={arrow} alt="arrow" />
              </NavLink>
            </div>

            <div class="home-service-box">
              <div>
                <img
                  src={socialMedia}
                  alt="social media"
                  style={{ width: "130px" }}
                />
              </div>
              <div class="home-s-heading">Social Media Marketing</div>
              <div class="home-s-content">
                Social media marketing involves using social platforms to engage
                audiences, build brand awareness, and drive sales.
              </div>
              <NavLink
                onClick={() => {
                  gotoTop();
                }}
                to=""
              >
                {" "}
                <button class="home-service-button">Explore now</button>
                <img class="arrow" src={arrow} alt="arrow" />
              </NavLink>
            </div>

            <div class="home-service-box">
              <div>
                <img
                  src={graphicsDesign}
                  alt="graphics design"
                  style={{ width: "130px" }}
                />
              </div>
              <div class="home-s-heading">Graphics Design</div>
              <div class="home-s-content">
                Graphic design is the art of creating visual content to
                communicate messages effectively through the use of typography,
                images, and layout.
              </div>
              <NavLink
                to="/services/uiux"
                onClick={() => {
                  gotoTop();
                }}
              >
                <button class="home-service-button">Explore now</button>
                <img class="arrow" src={arrow} alt="arrow" />
              </NavLink>
            </div>

            <div class="home-service-box">
              <div>
                <img
                  src={webHosting}
                  alt="web hosting"
                  style={{ width: "130px" }}
                />
              </div>
              <div class="home-s-heading">Web Hosting</div>
              <div class="home-s-content">
                Web hosting is the process of storing website files on servers
                and making them accessible to users via the internet.
              </div>
              <NavLink
                onClick={() => {
                  gotoTop();
                }}
                to="/services/seo"
              >
                {" "}
                <button class="home-service-button">Explore now</button>
                <img class="arrow" src={arrow} alt="arrow" />
              </NavLink>
            </div>
          </section>
        </div>
        <div>
          <div class="tech-container">
            <h2 class="home-service-heading">Technology</h2>
            <p class="home-service-custom">
              <strong>We service providing using the Technology</strong>
            </p>
            <div class="underline">
              <img src={underline} alt="underline" />
            </div>
            <div class="Technology">
              <div class="technology-box">
                <img src={image1} alt="technology1" style={{ width: 70 }} />
              </div>
              <div class="technology-box">
                <img src={image2} alt="technology2" style={{ width: 70 }} />
              </div>
              <div class="technology-box">
                <img src={image3} alt="technology3" style={{ width: 70 }} />
              </div>
              <div class="technology-box">
                <img src={image4} alt="technology4" style={{ width: 70 }} />
              </div>
              <div class="technology-box">
                <img src={image6} alt="technology5" style={{ width: 70 }} />
              </div>
              <div class="technology-box">
                <img src={image5} alt="technology6" style={{ width: 70 }} />
              </div>
            </div>
          </div>
        </div>
        <div class="service-container">
          <div class="it-service-company">
            <h2 class="explore">Explore</h2>
            <p class="it-services">
              <strong>Choose Best IT Service Company</strong>
            </p>
            <div class="underline1">
              <img src={underline1} alt="underline" />
            </div>
            <p class="c-content">
              When seeking the ideal IT service partner, it's important <br />{" "}
              to carefully consider their expertise, reliability, <br />{" "}
              security measures, and how well they align with your <br />{" "}
              business goals.
            </p>
            <div className="projects-stats">
              <strong className="stat-item">
                <span style={{ fontSize: "25px", fontWeight: "800" }}>
                  200+
                </span>{" "}
                <br />
                Happy Clients
              </strong>
              <strong className="stat-item center-stat-item">
                <span style={{ fontSize: "25px", fontWeight: "800" }}>30+</span>{" "}
                <br />
                Service Provide
              </strong>

              <strong className="stat-item">
                <span style={{ fontSize: "25px", fontWeight: "800" }}>30+</span>{" "}
                <br />
                Projects
              </strong>
            </div>
            <div>
              <NavLink
                to="/contact"
                onClick={() => {
                  gotoTop();
                }}
              >
                <button class="home-contact-us-button">Contact us</button>
              </NavLink>
            </div>
          </div>

          <div class="project-img">
            <img
              width="100%"
              height="320px"
              src={girl}
              alt="explore"
              style={{ paddingTop: 24 }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
