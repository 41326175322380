import React from "react";

import hero from "../../assets/images/CRM-img/crm.webp"
import circle1 from "../../assets/images/CRM-img/circle1.png"
import circle2 from "../../assets/images/CRM-img/circle2.png"
import rhombus from "../../assets/images/CRM-img/rectangle2.png"
import gif from "../../assets/images/CRM-img/gif.gif"
import line from "../../assets/images/CRM-img/line.svg"
import box1 from "../../assets/images/CRM-img/boxImg1.png"
import box2 from "../../assets/images/CRM-img/boxImg2.png"
import box3 from "../../assets/images/CRM-img/boxImg3.png"
import box4 from "../../assets/images/CRM-img/boxImg4.png"
import box5 from "../../assets/images/CRM-img/boxImg5.png"
import box6 from "../../assets/images/CRM-img/boxImg6.png"
import box7 from "../../assets/images/CRM-img/boxImg7.png"
import box8 from "../../assets/images/CRM-img/boxImg8.png"
import box9 from "../../assets/images/CRM-img/boxImg9.png"
import benefit1 from "../../assets/images/CRM-img/benefit1.png"
import benefit2 from "../../assets/images/CRM-img/benefit2.png"
import benefit3 from "../../assets/images/CRM-img/benefit3.png"
import benefit4 from "../../assets/images/CRM-img/benefit4.png"
import solution from "../../assets/images/CRM-img/IT.png"

import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet";
function CRM() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>CRM - TechiesGateway</title>
      </Helmet>
      <div className="crm-main-img">
        <img src={hero} alt="crm" />
        <img className="circle1" src={circle1} alt="circle" />
      </div>
      <div className="crm-container">
        <img className="circle2" src={circle2} alt="circle" />
        <img className="rectangle2" src={rhombus} alt="rhombus" />
        <div className="crm-intro ">
          <div className="crm-intro-content">
            <div className="crm-intro-content-text1">CRM</div>
            <div className="crm-intro-content-text2">
              We Build{" "}
              <span>
                {" "}
                Customer Relationship
                <br />
                Management
              </span>
            </div>
            <div className="crm-intro-content-text3">
              Our comprehensive CRM solutions streamline operations, automate
              tasks, and enhance customer interactions. With personalized
              communication and data-driven insights, we empower businesses to
              cultivate lasting relationships, drive sales efficiency, and
              foster customer satisfaction for sustainable growth.
            </div>
            <NavLink
              onClick={() => {
                gotoTop();
              }}
              to="/about"
            >
              <button class="crm-contact-us-button">Get more info</button>
            </NavLink>
          </div>
          <div className="crm-intro-img">
            <img className="crm-img1" src={gif} alt="gif" />
          </div>
        </div>
        <div className="crm-service ">
          <div className="crm-service-top">
            <h2 className="crm-top-heading blueText">Service</h2>
            <p class="crm-top-custom ">
              <strong>
                <span> Elevate your customer connections</span>with our CRM
                expertise.
              </strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="crm-boxes">
            <div className="crm-service-box">
              <div className="crm-service-box-img">
                <img src={box1} alt="contact management" />
              </div>
              <div className="crm-service-box-heading">Contact Management</div>
              <div className="crm-service-box-content">
                Effortlessly organize and manage your contacts with our CRM,
                ensuring a centralized hub for streamlined communication,
                enhanced customer interactions.
              </div>
            </div>
            <div className="crm-service-box ">
              <div className="crm-service-box-img">
                <img src={box2} alt="lead tracking" />
              </div>
              <div className="crm-service-box-heading">Lead Tracking</div>
              <div className="crm-service-box-content">
                Maximize your sales potential with lead tracking in our CRM,
                allowing you to efficiently capture, nurture, and convert leads
                into valuable customers.
              </div>
            </div>
            <div className="crm-service-box">
              <div className="crm-service-box-img">
                <img src={box3} alt="sales automation" />
              </div>
              <div className="crm-service-box-heading">Sales Automation</div>
              <div className="crm-service-box-content">
                Accelerate your sales processes with our CRM's powerful sales\
                automation. From lead management to closing deals, it
                streamlines tasks, boosts productivity.
              </div>
            </div>
            <div className="crm-service-box">
              <div className="crm-service-box-img">
                <img src={box4} alt="record management" />
              </div>
              <div className="crm-service-box-heading">Record Management</div>
              <div className="crm-service-box-content">
                Efficiently organize and secure business records with our CRM,
                ensuring streamlined management and quick access to essential
                information
              </div>
            </div>
            <div className="crm-service-box">
              <div className="crm-service-box-img">
                <img src={box5} alt="communication tracking" />
              </div>
              <div className="crm-service-box-heading">
                Communication and Interaction Tracking
              </div>
              <div className="crm-service-box-content">
                Enhance communication and track interactions. Centralize data
                for comprehensive insights, fostering effective engagement and
                relationship management.
              </div>
            </div>
            <div className="crm-service-box">
              <div className="crm-service-box-img">
                <img src={box6} alt="appointment scheduling" />
              </div>
              <div className="crm-service-box-heading">
                Appointment Scheduling
              </div>
              <div className="crm-service-box-content">
                Simplify and streamline appointment scheduling. Ensure efficient
                time management, reduce conflicts, and enhance overall
                organizational productivity effortlessly.
              </div>
            </div>
            <div className="crm-service-box">
              <div className="crm-service-box-img">
                <img src={box7} alt="inventory management" />
              </div>
              <div className="crm-service-box-heading">
                Inventory Management
              </div>
              <div className="crm-service-box-content">
                Optimize inventory effortlessly with our CRM. From tracking
                stock levels to managing orders, our system ensures efficient
                and accurate inventory management.
              </div>
            </div>
            <div className="crm-service-box">
              <div className="crm-service-box-img">
                <img src={box8} alt="billing " />
              </div>
              <div className="crm-service-box-heading">
                Billing and Invoicing
              </div>
              <div className="crm-service-box-content">
                Simplify billing and invoicing. Streamline financial processes,
                generate accurate invoices, and ensure seamless transactions for
                enhanced business efficiency.
              </div>
            </div>
            <div className="crm-service-box">
              <div className="crm-service-box-img">
                <img src={box9} alt="self-service portals" />
              </div>
              <div className="crm-service-box-heading">
                Customer Self-Service Portals
              </div>
              <div className="crm-service-box-content">
                Empower customers with our CRM's self-service portals, offering
                easy access to information and fostering satisfaction through
                personalized, independent interactions.
              </div>
            </div>
          </div>
        </div>
        <div className="crm-benifits">
          <div className="crm-benifits-top">
            <h2 className="crm-top-heading ">Benifits</h2>
            <p class="crm-top-custom">
              <strong>Benifits you will get</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underlne" />
            </div>
          </div>
          <div className="crm-boxes">
            <div className="crm-benifits-box">
              <div className="crm-benifits-box-top">
                <img src={benefit1} alt="benefits" />
                <div className="crm-benifits-box-top-heading">
                  <span>User-Friendly </span>
                  Interface
                </div>
              </div>
              <div className="crm-benifits-box-content">
                A user-friendly CRM interface enhances efficiency, reduces
                errors, and fosters positive user engagement for streamlined
                operations.
              </div>
            </div>
            <div className="crm-benifits-box">
              <div className="crm-benifits-box-top">
                <img src={benefit2} alt="benefits" />
                <div className="crm-benifits-box-top-heading ">
                  Customization and Scalability
                </div>
              </div>
              <div className="crm-benifits-box-content ">
                Customize and scale with ease in our CRM, tailoring solutions to
                unique needs while accommodating growth seamlessly.
              </div>
            </div>
            <div className="crm-benifits-box">
              <div className="crm-benifits-box-top">
                <img src={benefit3} alt="benefits" />
                <div className="crm-benifits-box-top-heading">
                  Data Security and Compliance
                </div>
              </div>
              <div className="crm-benifits-box-content">
                Ensure robust data security and compliance in our CRM,
                safeguarding sensitive information and meeting regulatory
                standards seamlessly.
              </div>
            </div>
            <div className="crm-benifits-box">
              <div className="crm-benifits-box-top">
                <img src={benefit4} alt="benefits" />
                <div className="crm-benifits-box-top-heading">
                  Dedicated Support
                </div>
              </div>
              <div className="crm-benifits-box-content">
                Experience unparalleled success wit CRM's dedicated support,
                ensuring seamless operations and optimal performance backed by
                expert assistance.
              </div>
            </div>
          </div>
        </div>
        <div className="crm-solutions">
          <div className="crm-solutions-top">
            <h2 className="crm-top-heading blueText">Performance Parameter</h2>
            <p class="crm-top-custom">
              <strong>IT Solutions You Trust</strong>
            </p>
            <div class="underline">
              <img src={line} alt="underline" />
            </div>
          </div>
          <div className="crm-boxes ">
            <div className="crm-solutions-box">
              <div className="crm-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="crm-solutions-box-heading">Enhance Security</div>
              <div className="crm-solutions-box-content">
                Enhanced website security <br /> protects sensitive data,
                <br />
                safeguards against cyber
                <br /> threats, and fosters user trust.
              </div>
            </div>
            <div className="crm-solutions-box">
              <div className="crm-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="crm-solutions-box-heading">Reliability</div>
              <div className="crm-solutions-box-content">
                It is the ability of a website to function as intended,
                providing users with a seamless and dependable experience.
              </div>
            </div>
            <div className="crm-solutions-box">
              <div className="crm-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="crm-solutions-box-heading">Faster Loading</div>
              <div className="crm-solutions-box-content">
                It enhances user experience by reducing page load times and
                improving website responsiveness.
              </div>
            </div>
            <div className="crm-solutions-box">
              <div className="crm-solutions-box-img">
                <img src={solution} alt="solutions" />
              </div>
              <div className="crm-solutions-box-heading">Scalability</div>
              <div className="crm-solutions-box-content">
                It ensures a website's ability to handle increasing traffic and
                data demands without compromising performance.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CRM;
