import React from "react";
import circle1 from "../../../assets/images/web-dev/web-circle.png";
import circle2 from "../../../assets/images/web-dev/webcircle2.png";
import rhombus from "../../../assets/images/web-dev/web-rectangle1.png";
import mobile from "../../../assets/images/app-dev/mobile.webp";
import project1 from "../../../assets/images/app-dev/appsample1.png";
import project2 from "../../../assets/images/app-dev/appsample2.png";
import project3 from "../../../assets/images/app-dev/appsample3.png";
import category1 from "../../../assets/images/web-dev/ca1.png";
import category2 from "../../../assets/images/web-dev/ca2.png";
import category3 from "../../../assets/images/web-dev/ca3-removebg-preview.png";
import category4 from "../../../assets/images/web-dev/ca4.png";
import category5 from "../../../assets/images/web-dev/ca5.png";
import category6 from "../../../assets/images/web-dev/ca6.png";
import category7 from "../../../assets/images/web-dev/ca7.png";
import category8 from "../../../assets/images/web-dev/ca8.png";
import category9 from "../../../assets/images/web-dev/ca9-removebg-preview.png";
import category10 from "../../../assets/images/web-dev/ca10-removebg-preview.png";
import category11 from "../../../assets/images/web-dev/ca11.png";
import category12 from "../../../assets/images/web-dev/ca12.png";
import benefit1 from "../../../assets/images/web-dev/be1.png";
import benefit2 from "../../../assets/images/web-dev/be2.png";
import benefit3 from "../../../assets/images/web-dev/be3.png";
import benefit5 from "../../../assets/images/web-dev/be5-removebg-preview.png";
import benefit7 from "../../../assets/images/web-dev/be7.png";
import benefit8 from "../../../assets/images/web-dev/be8.png";
import benefit10 from "../../../assets/images/web-dev/be10.png";
import benefit11 from "../../../assets/images/web-dev/be11.png";
import icon5 from "../../../assets/images/web-dev/icon5.svg";
import icon1 from "../../../assets/images/web-dev/icon1.webp";
import icon2 from "../../../assets/images/web-dev/icon2.png";
import icon3 from "../../../assets/images/web-dev/icon3.svg";
import icon4 from "../../../assets/images/web-dev/icon4.png";
import hero from "../../../assets/images/app-dev/app-intro.webp";
import solution from "../../../assets/images/CRM-img/IT.png";
import line from "../../../assets/images/CRM-img/line.svg";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

function index() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>AppDev - TechiesGateway</title>
      </Helmet>
      <div className="app">
        <img className="app-circle1" src={circle2} alt="circle" />
        <img className="app-circle2" src={circle1} alt="circle" />
        <img className="app-circle4" src={rhombus} alt="rhombus" />
        <img className="app-circle3" src={circle1} alt="circle" />
        <div className="app-intro">
          <div className="app-intro-content">
            <div className="app-intro-content-text1 app-intro-blueText">
              App Development
            </div>
            <div className="app-intro-content-text2">
              We Build creative{" "}
              <span className="block1">
                Modern <span className="block"> App</span>
              </span>
            </div>
            <div id="pricing-btn">Starts from ₹1000/-</div>
            <div className="app-intro-content-text3">
              Our innovative app developers transform ideas into reality,
              crafting intuitive and engaging mobile apps that align with
              client's needs and business objectives
            </div>
            <NavLink
              to="/contact"
              onClick={() => {
                gotoTop();
              }}
            >
              <button class="app-contact-us-button">Contact Us</button>
            </NavLink>
          </div>
          <div className="app-intro-img">
            <img className="app-img1" src={hero} alt="app-development" />
          </div>
        </div>
        <div className="app-container">
          <div className="app-design">
            <div className="app-design-imgs">
              <img className="app-design-img" src={mobile} alt="App Dev" />
            </div>
            <div className="app-design-content">
              <div className="app-top-design-heading ">
                Mobile App Development/ Design
              </div>
              <div className="app-design-content-custom">
                <strong> Mobile App Development/ Design</strong>
              </div>
              <div class="app-design-underline">
                <img src={line} alt="underline" />
              </div>
              <div className="app-design-content-description">
                We design and build apps that are easy to use, look great. We
                use the latest technology to make sure your app is fast, secure,
                and up-to-date. We work closely with you to understand your
                needs and goals, and we're always happy to answer your questions
                and help you through the process.
              </div>
              <div className="app-design-icons">
                <div className="app-design-icon">
                  <img src={icon1} alt="tech1" />
                </div>
                <div className="app-design-icon">
                  <img src={icon2} alt="tech2" />
                </div>
                <div className="app-design-icon">
                  <img src={icon3} alt="tech3" />
                </div>
                <div className="app-design-icon">
                  <img src={icon4} alt="tech4" />
                </div>
                <div className="app-design-icon">
                  <img src={icon5} alt="tech5" />
                </div>
              </div>
              <NavLink
                to="/contact"
                onClick={() => {
                  gotoTop();
                }}
              >
                <button class="app-contact-us-button">Contact Us</button>
              </NavLink>
            </div>
          </div>
          <div className="app-category">
            <div className="app-category-top">
              <p className="app-top-heading">Category</p>
              <p class="web-top-custom ">
                <strong>Categories of website development</strong>
              </p>
              <div class="web-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="app-category-boxes web-boxes">
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category1} alt="categories" />
                </div>
                <div className="app-category-box-text">Corporate website</div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category2} alt="categories" />
                </div>
                <div className="app-category-box-text">Personal website</div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category3} alt="categories" />
                </div>
                <div className="app-category-box-text">Government website</div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category4} alt="categories" />
                </div>
                <div className="app-category-box-text">Ecommerce website</div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category5} alt="categories" />
                </div>
                <div className="app-category-box-text">Educational website</div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category6} alt="categories" />
                </div>
                <div className="app-category-box-text">NGO website</div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category7} alt="categories" />
                </div>
                <div className="app-category-box-text">
                  Health and wellness
                  <br /> website
                </div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category8} alt="categories" />
                </div>
                <div className="app-category-box-text">Restaurant website</div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category9} alt="categories" />
                </div>
                <div className="app-category-box-text">Blog/ News website</div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category10} alt="categories" />
                </div>
                <div className="app-category-box-text">
                  Event Ticket website
                </div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category11} alt="categories" />
                </div>
                <div className="app-category-box-text">Real State website</div>
              </div>
              <div className="app-category-box">
                <div className="app-category-box-img">
                  <img src={category12} alt="categories" />
                </div>
                <div className="app-category-box-text">
                  Travel and Tourism <br />
                  website
                </div>
              </div>
            </div>
          </div>
          <div className="app-benifits">
            <div className="app-benifits-top">
              <h2 className="app-top-heading ">Benifits</h2>
              <p class="app-top-custom">
                <strong>Benifits you will get</strong>
              </p>
              <div class="app-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="app-boxes">
              <div className="app-benifits-box">
                <div className="app-benifits-box-top">
                  <img src={benefit1} alt="benefits" />
                  <div className="app-benifits-box-top-heading">
                    Data driven Analysis
                  </div>
                </div>
                <div className="app-benifits-box-content">
                  Harnessing user data to craft intuitive app experiences.
                </div>
              </div>
              <div className="app-benifits-box">
                <div className="app-benifits-box-top">
                  <img src={benefit2} alt="benefits" />
                  <div className="app-benifits-box-top-heading">
                    1 Year Maintenance
                  </div>
                </div>
                <div className="app-benifits-box-content">
                  Leveraging data insights to optimize app performance and
                  sustain user engagement over a year.
                </div>
              </div>
              <div className="app-benifits-box">
                <div className="app-benifits-box-top">
                  <img src={benefit3} alt="benefits" />
                  <div className="app-benifits-box-top-heading">
                    Play store support
                  </div>
                </div>
                <div className="app-benifits-box-content">
                  Publishing an app on the Play Store, including a help center,
                  community forum, phone support, web form and in-app support.
                </div>
              </div>
              <div className="app-benifits-box">
                <div className="app-benifits-box-top">
                  <img src={benefit5} alt="benefits" />
                  <div className="app-benifits-box-top-heading">Security</div>
                </div>
                <div className="app-benifits-box-content">
                  Seo refer that type of content that's created in a way that
                  helps the search engines rank it high
                </div>
              </div>
              <div className="app-benifits-box">
                <div className="app-benifits-box-top">
                  <img src={benefit7} alt="benefits" />
                  <div className="app-benifits-box-top-heading">
                    Business Email
                  </div>
                </div>
                <div className="app-benifits-box-content">
                  Business email is a professional communication tool that helps
                  businesses connect with customers, partners and colleagues.
                </div>
              </div>
              <div className="app-benifits-box">
                <div className="app-benifits-box-top">
                  <img src={benefit8} alt="benefits" />
                  <div className="app-benifits-box-top-heading">
                    WhatsApp Integrate
                  </div>
                </div>
                <div className="app-benifits-box-content">
                  WhatsApp integration enables seamless communication between
                  App visitors and business representatives through WhatsApp.
                </div>
              </div>
              <div className="app-benifits-box">
                <div className="app-benifits-box-top">
                  <img src={benefit10} alt="benefits" />
                  <div className="app-benifits-box-top-heading">
                    Social Media Setup
                  </div>
                </div>
                <div className="app-benifits-box-content">
                  Social media setup involves creating and optimizing social
                  media profiles for a business or individual.
                </div>
              </div>
              <div className="app-benifits-box">
                <div className="app-benifits-box-top">
                  <img src={benefit11} alt="benefits" />
                  <div className="app-benifits-box-top-heading">Live Chat</div>
                </div>
                <div className="app-benifits-box-content">
                  Live chat provides real-time online customer support through
                  text-based chat windows.
                </div>
              </div>
            </div>
          </div>
          <div className="app-solutions">
            <div className="app-solutions-top">
              <h2 className="app-top-heading blueText">
                Performance Parameter
              </h2>
              <p class="app-top-custom">
                <strong>IT Solutions You Trust</strong>
              </p>
              <div class="app-underline">
                <img src={line} alt="underlin" />
              </div>
            </div>
            <div className="app-boxes ">
              <div className="app-solutions-box">
                <div className="app-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="app-solutions-box-heading">
                  Enhance Security
                </div>
                <div className="app-solutions-box-content">
                  Enhanced App security <br /> protects sensitive data,
                  <br />
                  safeguards against cyber
                  <br /> threats, and fosters user trust.
                </div>
              </div>
              <div className="app-solutions-box">
                <div className="app-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="app-solutions-box-heading">Reliability</div>
                <div className="app-solutions-box-content">
                  It is the ability of a App to function as intended, providing
                  users with a seamless and dependable experience.
                </div>
              </div>
              <div className="app-solutions-box">
                <div className="app-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="app-solutions-box-heading">Faster Loading</div>
                <div className="app-solutions-box-content">
                  It enhances user experience
                  <br /> by reducing page load
                  <br /> times and improving App responsiveness.
                </div>
              </div>
              <div className="app-solutions-box">
                <div className="app-solutions-box-img">
                  <img src={solution} alt="solutions" />
                </div>
                <div className="app-solutions-box-heading">Scalability</div>
                <div className="app-solutions-box-content">
                  It ensures a App's ability to handle increasing traffic and
                  data demands without compromising performance.
                </div>
              </div>
            </div>
          </div>
          <div className="app-samples">
            <div className="app-samples-top">
              <h2 className="app-top-heading ">Work Sample</h2>
              <p class="app-top-custom">
                <strong>Why you choose us</strong>
              </p>
              <div class="app-underline">
                <img src={line} alt="underline" />
              </div>
            </div>
            <div className="app-boxes">
              <div className="app-sample-box">
                <a
                  href="http://https://play.google.com/store/apps/dev?id=7111598954945638845"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="app-sample-box-img">
                    <img src={project1} alt="project1" />
                  </div>
                </a>
                <div className="app-sample-box-contents">
                  <div className="app-sample-box-content1">E-learning App</div>
                  <div className="app-sample-box-content2">PRAN App</div>
                </div>
              </div>
              <div className="app-sample-box">
                <a
                  href="http://https://play.google.com/store/apps/dev?id=7111598954945638845"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="app-sample-box-img">
                    <img src={project2} alt="project2" />
                  </div>
                </a>
                <div className="app-sample-box-contents">
                  <div className="app-sample-box-content1">Restaurant App</div>
                  <div className="app-sample-box-content2">MEDEV</div>
                </div>
              </div>
              <div className="app-sample-box">
                <a
                  href="http://https://play.google.com/store/apps/dev?id=7111598954945638845"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="app-sample-box-img">
                    <img src={project3} alt="project3" />
                  </div>
                </a>
                <div className="app-sample-box-contents">
                  <div className="app-sample-box-content1">Guidance App</div>
                  <div className="app-sample-box-content2">E-expert</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
